import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {fifteenthGrey, greyishBlue500} from "assets/jss/main";

CardTitle.propTypes = {
    titleId: PropTypes.string.isRequired,
    subtitleId: PropTypes.string,
    titleRightContent: PropTypes.any
};

function CardTitle({titleId, subtitleId, titleRightContent}) {
    return (
        <div style={{ whiteSpace: 'nowrap' }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <h2 style={{
                    fontSize: '24px',
                    marginTop: 'unset',
                    color: fifteenthGrey,
                    marginBottom: "unset",
                    textTransform: "none"
                }}>
                    <FormattedMessage id={titleId} defaultMessage={titleId} />
                </h2>
                {titleRightContent && (
                    <div style={{marginLeft: '8px'}}>
                        {titleRightContent}
                    </div>
                )}
            </div>
            {subtitleId && (
                <span style={{
                    color: greyishBlue500,
                    fontSize: '16px',
                    fontWeight: 400,
                    textTransform: 'none'
                }}>
                    <FormattedMessage id={subtitleId} />
                </span>
            )}
        </div>
    );
}

export default CardTitle;
