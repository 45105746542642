import React, {createContext, useState} from "react";
import * as moment from "moment/moment";

const DashboardDateRangePickerContext = createContext(null);

const DashboardDateRangePickerContextProvider = ({ children }) => {
    const date = new Date();
    const defaultEndDate = moment(date);

    date.setDate(date.getDate() - 7);
    const defaultStartDate = moment(date);

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [initialStartDate] = useState(defaultStartDate);

    const [endDate, setEndDate] = useState(moment(defaultEndDate));
    const [initialEndDate] = useState(defaultEndDate);

    const [dateChangedTrigger, setDateChangedTrigger] = useState(false);

    const [focusedInput, setFocusedInput] = useState(null)

    const [unit, setUnit] = useState('day');
    const [unitTooltip, setUnitToolTip] = useState('D MMMM YYYY');
    const [unitParser, setUnitParser] = useState('YYYYMMDD');

    function handleDateChange(newStartDate, newEndDate) {
        if (newStartDate === null) {
            newStartDate = initialStartDate;
        }
        if (newEndDate === null) {
            newEndDate = initialEndDate;
        }

        const startDate = new Date(newStartDate.format("YYYY-MM-DD"));
        const endDate = new Date(newEndDate.format("YYYY-MM-DD"));

        const timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (diffDays > 365 * 3) {
            setUnit('year');
            setUnitToolTip('YYYY')
            setUnitParser('YYYY')
        } else if (diffDays > 365 / 12) {
            setUnit('month');
            setUnitToolTip('MMMM YYYY')
            setUnitParser('YYYYMM')
        } else {
            setUnit('day');
            setUnitToolTip('D MMMM YYYY');
            setUnitParser('YYYYMMDD');
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);

        setDateChangedTrigger(!dateChangedTrigger);
    }

    return (
        <DashboardDateRangePickerContext.Provider
            value={{
                startDate,
                endDate,
                focusedInput,
                setStartDate,
                setEndDate,
                setFocusedInput,
                unit,
                unitTooltip,
                unitParser,
                dateChangedTrigger,
                handleDateChange
            }}
        >
            {children}
        </DashboardDateRangePickerContext.Provider>
    )
}

export { DashboardDateRangePickerContext, DashboardDateRangePickerContextProvider };
