import {fetch, normalize} from "utils/dataAccess";

export default function show(id) {
    return new Promise((resolve, reject) => {
        fetch(`/retailers/${id}`).then(response => {
            response.json().then(response => {
                resolve(normalize(response))
            })
        }).catch(error => reject(error));
    });
}

export function showProfile(id) {
    return new Promise((resolve, reject) => {
        fetch(`/retailer_profiles/${id}`).then(response => {
            response.json().then(response => {
                resolve(normalize(response, false))
            })
        }).catch(error => reject(error));
    });
}
