import React from 'react';

import Grid from "@mui/material/Grid";

import FormTranslator from "components/translator/formTranslator";
import FormBloc from "components/form/FormBloc";

import NameField from "./components/NameField";
import StockField from "./components/StockField";
import PriceField from "./components/PriceField";
import OutletPriceField from "./components/OutletPriceField";
import DiscountedPriceField from "./components/DiscountedPriceField";
import WeightField from "./components/WeightField";
import EcoTaxField from "./components/EcoTaxField";
import UnavailableBeforeField from "./components/UnavailableBeforeField";
import StatusField from "./components/StatusField";

function MainInformation(props) {
    return (
        <FormBloc titleId='product.show.variation.form.mainInformation.title'>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Grid item container columnSpacing={2}>
                        <Grid item md={9} xs={12}>
                            <StatusField />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormTranslator formGenerator={(index, code) => (
                        <Grid container direction='column' rowSpacing={3}>
                            <Grid item container columnSpacing={2}>
                                <Grid item md={9} xs={12}>
                                    <NameField localeCode={code} />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <StockField />
                                </Grid>
                            </Grid>
                            <Grid item container columnSpacing={2}>
                                <Grid item md={4} xs={12}>
                                    <PriceField />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <OutletPriceField />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <DiscountedPriceField />
                                </Grid>
                            </Grid>
                            <Grid item container columnSpacing={2}>
                                <Grid item md={3} xs={12}>
                                    <WeightField />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <EcoTaxField />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <UnavailableBeforeField />
                                </Grid>
                            </Grid>
                        </Grid>
                    )} />
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default MainInformation;
