import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";

import Badge from "components/Badge";

StatusBadge.propTypes = {
    status: PropTypes.bool.isRequired,
    isVariation: PropTypes.bool
};

function StatusBadge({status, isVariation = false, ...props}) {
    let disabledStatusLabel = props.intl.formatMessage({id: 'product.show.product.form.status.disabled'});
    let enabledStatusLabel = props.intl.formatMessage({id: 'product.show.product.form.status.enabled'});

    if (isVariation) {
        disabledStatusLabel = props.intl.formatMessage({id: 'product.show.variation.form.status.disabled'});
        enabledStatusLabel = props.intl.formatMessage({id: 'product.show.variation.form.status.enabled'});
    }

    return (
        <Badge
            label={status ? enabledStatusLabel : disabledStatusLabel}
            color={status ? 'success' : 'error'}
            size={'large'}
            indicator={true}
        />
    );
}

export default injectIntl(StatusBadge);
