import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {BeatLoader, PropagateLoader} from 'react-spinners';

// @mui/material components
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Chip,
    Checkbox,
    Collapse,
    Divider,
    IconButton,
    Select,
    OutlinedInput,
    Tabs,
    Tab,
    FormControl,
    FormControlLabel,
    MenuItem,
    TextField,
    Input,
    InputBase,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    RadioGroup,
    Radio,
    Button as MaterialUiButton,
    Hidden,
    ButtonGroup,
} from "@mui/material"

// @mui/icons-material
import {
    UnfoldMore,
    RemoveRounded,
    AddRounded,
    NotificationsActive,
    Search,
    ImageSharp,
    ArrowBackIos,
    ViewHeadline,
    KeyboardArrowDown,
    KeyboardArrowRight,
    Delete,
    GetApp,
} from "@mui/icons-material";

// core components
import TableHead from "../components/tableHead";
import PaginationContainer from "components/pagination/paginationContainer";
import Button from "components/button/button"
import Snackbar from "components/snackbar/snackbar";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import CardFooter from "components/card/cardFooter";
import CustomDate from "components/date/date";
import ItemsPerPageSelect from "components/select/itemsPerPageSelect";

// styles
import listStyle from "assets/jss/views/product/variation/listStyle";
import {primaryColor} from "assets/jss/main";

// action
import {list, reset, downloadExport} from "actions/variation/list";
import {brandList} from "actions/brand/list";
import {categoryList} from "actions/category/list";
import {genderList} from "actions/gender/list";
import {massUpdate, reset as massUpdateReset} from "actions/variation/massUpdate";
import retailerOptionValuesList from "api/retailerOptionValues/list"

// utils
import {sortOn} from "utils/sortOn";
import {hasAuthorization, Authorizations} from "utils/authorizations";
import moment from "moment";
import FormattedNumberWithCurrency from "../../../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import abort from "utils/abort";
import {fetch} from "../../../../../utils/dataAccess";
import {encodePercentageCharacter} from "utils/encodePercentageCharacter";
import {getFirstProductImage} from "utils/getFirstProductImage";
import {stockEasyOffer} from "../../../../../domain/enum/offers";
import {UserAgentUtils} from "../../../../../utils/useragent";
import {getTranslation} from "../../../../../domain/helpers/translations";
import {withStyles} from "@mui/styles";
import {VariationFilter} from "components/filter/FilterEnum";

class EnhancedTable extends Component {
    static propTypes = {
        error: PropTypes.string,
        retrieved: PropTypes.object,
        loading: PropTypes.bool,
        list: PropTypes.func,
        brandList: PropTypes.func,
        categoryList: PropTypes.func,
        genderList: PropTypes.func,
        massUpdate: PropTypes.func,
        retailerOptionValuesList: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            itemsPerPage: '50',
            page: 1,
            selected: [],
            filterMenuSelected: "StatusStock",
            selectedFilters: [],
            minPriceFilter: 0,
            maxPriceFilter: 0,
            hideFilter: true,
            action: "",
            addCategoriesDialog: false,
            deleteCategoriesDialog: false,
            deleteProductsDialog: false,
            genderDialog: false,
            brandDialog: false,
            categoriesToAdd: [],
            categoriesToDelete: [],
            genderSelected: '',
            brandSelected: '',
            showNotification: true,
            searchCategories: '',
            searchBrand: '',
            showChildren: [],
            childrenColumns: [],
            isSelectAllChecked: false,
            isTotalSelected: false,
            totalProductSelected: null,
            loadingAllProduct : false,
            allGendersFetched : false,
            allBrandsFetched : false,
            allCategoriesFetched : false,
            allGenders : [],
            allBrands : [],
            allCategories : [],
            baseParamsUrl: '/retailers/show/',
            retailerOptionValuesList: null,
        };

        this.handleChangeAction = this.handleChangeAction.bind(this);
    };

    componentDidMount() {
        const {list} = this.props;

        if (this.props.offer === stockEasyOffer) {
            this.setState({ baseParamsUrl: '/stockeasy/show/retailers/' })
        }

        let retailerOptionValuesOrdered = [];
        let retailer = decodeURIComponent(this.props.match.params.id);
        retailerOptionValuesList(`pagination=false&optionUsedOnVariation=true&retailer_id=${retailer.replace("/retailers/", "")}`)
            .then(retailerOptions => {
                Object.keys(retailerOptions).forEach(function(optionValue) {
                    const optionName = getTranslation(retailerOptions[optionValue]['option'] ?? {translations: []})?.name;
                    if (!retailerOptionValuesOrdered[optionName]) {
                        retailerOptionValuesOrdered[optionName] = [];
                    }
                    retailerOptionValuesOrdered[optionName].push(retailerOptions[optionValue]);
                });
            })
            .finally(() => this.setState({retailerOptionValuesList: retailerOptionValuesOrdered}));

        if (this.props.match.params.params) {
            let params = decodeURIComponent(encodePercentageCharacter(this.props.match.params.params)).split("&");

            if (params.length !== 3 && this.props.offer !== stockEasyOffer) {
                this.handleRouteReset();
                return;
            }

            this.updateStoreParameters(params);

            list(decodeURIComponent(encodePercentageCharacter(this.props.match.params.params)));
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.massUpdateSuccess !== nextProps.massUpdateSuccess) {
            this.props.list(decodeURIComponent(this.props.match.params.params));

            this.setState({
                loadingAllProduct: false
            });

            this.props.massUpdateReset();
        }

        if (this.props.match.params.params !== nextProps.match.params.params) {
            window.scrollTo(0, 0);

            let params = decodeURIComponent(encodePercentageCharacter(nextProps.match.params.params)).split("&");

            let newParams = [];

            for(let param of params){
                let tmpValues = param.split("=");

                newParams.push(tmpValues[0] + "=" + encodeURIComponent(tmpValues[1]));
            }

            nextProps.list(
                nextProps.match.params.params &&
                newParams.join('&')
            );

            this.setState({
                isSelectAllChecked: false,
                selected: [],
                isTotalSelected: false,
                totalProductSelected: null
            });

            // reset filters when reload page
            this.updateStoreParameters(params, params.length === 3);
        }
    };

    componentWillUnmount() {
        this.props.reset();
        abort.abortRequests();
    }

    resetSelectedAndCloseDialogAndRefreshList = (dialogName = false) => {
        this.setState({
            selected: [],
            isSelectAllChecked: false
        });

        if (dialogName) {
            this.handleDialog(dialogName);
        }

        this.props.list(decodeURIComponent(this.props.match.params.params));
    };

    updateStoreParameters(params, resetFilters = false) {
        params.map((param) => {
            return this.setState({[param.split("=")[0]]: param.split("=")[1]})
        });

        if (resetFilters) {
            this.setState({selectedFilters: []})
        }
    }

    getCategoriesByVariations = (variationsArray) => {
        let categoriesArray = [];

        variationsArray.forEach(variation => {
            variation.product.categories.forEach(cat => {
                categoriesArray.push(cat);
            })
        });

        return this.groupCategories(categoriesArray);
    };

    groupCategories = (categoriesArray) => {
        let uniqueCatArray = [];

        // check if the parent of variation is already in the array
        categoriesArray.forEach(cat => {
            let isCatAlreadyAdded = uniqueCatArray.some(item => item.id === cat.id)
            if (!isCatAlreadyAdded) {
                uniqueCatArray.push(cat);
            }
        });

        return uniqueCatArray.sort(sortOn("name"));
    };

    // duplicate in filterMenu.js
    checkIfFilterExist = (element) => {
        const {selectedFilters} = this.state;

        for (let i = 0; i < selectedFilters.length; i++) {
            if (selectedFilters[i].id === element.id) {
                return true
            }
        }

        return false;
    };

    handleRouteReset() {
        document.getElementById("search-input").value = "";
        delete this.state[`order[outletPrice]`];
        delete this.state[`order[discountedPrice]`];
        delete this.state[`order[lastImportedAt]`];
        delete this.state[`order[soldOnline]`];
        this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/variations/${encodeURIComponent(`retailer.id=${decodeURIComponent(this.props.match.params.id).replace("/retailers/", "")}&itemsPerPage=${this.state.itemsPerPage}&page=1`)}`);
    }

    handlePageReset = (params) => {
        params = params.split("&");

        params.map((param, key) => {
            if (param.search("page") !== -1) {
                params[key] = "page=1";
            }

            return true;
        });

        return params;
    };

    handleRequestSort = (event, property) => {
        const params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let newParams = "";
        let order = this.state[`order[${property}]`];

        params.map((param) => {
            if (param.search("order") === -1) {
                newParams += `${param}&`;
            } else {
                delete this.state[`${param.split("=")[0]}`]
            }

            return true;
        });

        if (order !== undefined) {
            order = ("asc" === order) ? "desc" : "asc";

            if (property === "outletPrice") {
                newParams += `order[discountedPrice]=${order}&order[outletPrice]=${order}`;
            } else {
                newParams += `order[${property}]=${order}`;
            }
        } else {
            if (property === "outletPrice") {
                newParams += `order[discountedPrice]=desc&order[outletPrice]=desc`;
                this.setState({
                    [`order[discountedPrice]`]: "desc",
                    [`order[outletPrice]`]: "desc"
                });
            } else {
                newParams += `order[${property}]=desc`;

                this.setState({[`order[${property}]`]: "desc"});
            }
        }

        this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/variations/${encodeURIComponent(newParams)}`);
    };

    handleSearch = (event) => {
        abort.abortRequests();

        this.setState({name: event.target.value});

        let params = this.handlePageReset(decodeURIComponent(encodePercentageCharacter(this.props.match.params.params)));
        let newParams = "";

        if (event.target.value.length > 0) {
            let search = encodePercentageCharacter(event.target.value);

            newParams = `translations.name=${search}`;
        }

        params.map((param) => {
            if (param.search("translations.name=") === -1) {
                newParams += `&${param}`;
            }

            return true;
        });

        this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/variations/${encodeURIComponent(newParams)}`);
    };

    handleAddSelectedFilter = element => {
        if (!this.checkIfFilterExist(element)) {
            this.setState({selectedFilters: [...this.state.selectedFilters, element]}, () => this.handleUpdateAddFilter(element, this.state.selectedFilters));
        }
    };

    // duplicate in filterMenu.js
    handleUpdateAddFilter(element, selectedFilters) {
        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let newParams = "";

        // this prevents duplication of filter in url
        params.map((param) => {
            if ((param.search("stock") === -1 || element["@type"] !== "Stock") &&
                (param.search("status") === -1 || element["@type"] !== "Status") &&
                (param.search("validated") === -1 || element["@type"] !== "Validated") &&
                (param.search("eshopId") === -1 || element["@type"] !== "EshopId") &&
                (param.search("categories") === -1 || element["@type"] !== "Category") &&
                (param.search("brand") === -1 || element["@type"] !== "Brand") &&
                (param.search("gender") === -1 || element["@type"] !== "Gender") &&
                (param.search("options") === -1 || element["@type"] !== "RetailerOptionValue") &&
                (param.search(VariationFilter.lock.property) === -1 || element["@type"] !== VariationFilter.lock.type)) {
                newParams += `${param}&`;
            }

            return true;
        });

        switch (element["@type"]) {
            case "Brand":
                let newBrands = [];
                selectedFilters.filter(filter => filter["@type"] === "Brand").forEach(brand => {
                    newBrands.push(`"${brand.id}"`);
                });
                newParams += `brand.id=[${newBrands}]`;
                break;
            case "Category":
                let newCategories = [];
                selectedFilters.filter(filter => filter["@type"] === "Category").forEach(category => {
                    newCategories.push(category.id);
                });
                newParams += `categories.id={${newCategories.toString()}}`;
                break;
            case "Gender":
                let newGenders = [];
                selectedFilters.filter(filter => filter["@type"] === "Gender").forEach(gender => {
                    newGenders.push(`"${gender.id}"`);
                });
                newParams += `gender.id=[${newGenders}]`;
                break;
            case "Status":
                newParams += `status=${element.value}`;
                selectedFilters.map((filter) => {
                    if (filter["@type"] === "Status" && element.value !== filter["value"]) {
                        let oldStockFilterIndex = selectedFilters.indexOf(filter);
                        selectedFilters.splice(oldStockFilterIndex, 1);
                        this.setState({selectedFilters: selectedFilters});
                    }

                    return true;
                });

                break;
            case "Validated":
                newParams += `validated=${element.value}`;
                selectedFilters.map((filter) => {
                    if (filter["@type"] === "Validated" && element.value !== filter["value"]) {
                        let oldValidatedFilterIndex = selectedFilters.indexOf(filter);
                        selectedFilters.splice(oldValidatedFilterIndex, 1);
                        this.setState({selectedFilters: selectedFilters});
                    }

                    return true;
                });

                break;
            case "EshopId":
                newParams += `exists[eshopId]=${element.value}`;
                selectedFilters.map((filter) => {
                    if (filter["@type"] === "EshopId" && element.value !== filter["value"]) {
                        let oldEshopIdFilterIndex = selectedFilters.indexOf(filter);
                        selectedFilters.splice(oldEshopIdFilterIndex, 1);
                        this.setState({selectedFilters: selectedFilters});
                    }

                    return true;
                });

                break;
            case "Stock":
                if (element.value === "out") {
                    newParams += "stock[lte]=0";
                } else if (element.value === "middle") {
                    newParams += "stock[gt]=0&stock[lte]=5";
                } else {
                    newParams += "stock[gt]=0";
                }
                selectedFilters.map((filter) => {
                    if (filter["@type"] === "Stock" && element.value !== filter["value"]) {
                        let oldStockFilterIndex = selectedFilters.indexOf(filter);
                        selectedFilters.splice(oldStockFilterIndex, 1);
                        this.setState({selectedFilters: selectedFilters});
                    }

                    return true;
                });

                break;
            case VariationFilter.lock.type:
                const selectedLockFilters = selectedFilters.filter(filter => filter["@type"] === VariationFilter.lock.type);
                const combinedFilters = [VariationFilter.lock.values.ALL, VariationFilter.lock.values.VARIATION];

                let lockFiltersToRemove = [];
                let newLockFilter;

                if (combinedFilters.includes(element.value)) {
                    // if the filter is a combined filter
                    // keep only the new selected lock filter as the only lock filter
                    lockFiltersToRemove = selectedLockFilters.filter(filter => element.value !== filter["value"]);

                    newLockFilter = element.value;
                } else {
                    // remove combined lock filter
                    lockFiltersToRemove = selectedLockFilters.filter(filter => combinedFilters.includes(filter["value"]))

                    newLockFilter = selectedLockFilters.map(lock => lock.value);
                }

                if (newLockFilter) {
                    newParams += `${VariationFilter.lock.property}=${newLockFilter}`;
                }

                this.removeFiltersFromSelectedFilters(lockFiltersToRemove)
                break;
            case "RetailerOptionValue":
                let newOptions = [];
                selectedFilters.filter(filter => filter["@type"] === "RetailerOptionValue").forEach(option => {
                    newOptions.push(`"${option.id}"`);
                });
                newParams += `options.id=[${newOptions}]`;
                break;
            default:
                newParams = ""
        }

        this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/variations/${encodeURIComponent(newParams)}`);
    }

    // remove several filters at once
    // duplicate in filterMenu.js
    removeFiltersFromSelectedFilters(filters) {
        const {selectedFilters} = this.state;

        if (filters.length === 0) {
            return;
        }

        filters.forEach((filter) => {
            let oldFilterIndex = selectedFilters.indexOf(filter);
            selectedFilters.splice(oldFilterIndex, 1);
        });

        this.setState({selectedFilters: selectedFilters});
    }

    // remove one filter at once
    // duplicate in filterMenu.js
    removeFilterValueFromSelectedFilters(filter) {
        const {selectedFilters} = this.state;

        let oldFilterIndex = selectedFilters.indexOf(filter);
        selectedFilters.splice(oldFilterIndex, 1);

        this.setState({selectedFilters: selectedFilters});
    }

    // duplicate in filterMenu.js
    handleDeleteSelectedFilter = element => {
        let selectedFilters = this.state.selectedFilters;

        if (this.checkIfFilterExist(element)) {
            let index = selectedFilters.indexOf(element);

            selectedFilters.splice(index, 1);
            this.setState({selectedFilters});

            let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
            let newParams = "";

            // this prevents duplication of filter in url
            params.map((param) => {
                if ((param.search("stock") === -1 || element["@type"] !== "Stock") &&
                    (param.search("status") === -1 || element["@type"] !== "Status") &&
                    (param.search("validated") === -1 || element["@type"] !== "Validated") &&
                    (param.search("eshopId") === -1 || element["@type"] !== "EshopId") &&
                    (param.search("brand") === -1 || element["@type"] !== "Brand") &&
                    (param.search("options") === -1 || element["@type"] !== "RetailerOptionValue") &&
                    (param.search("gender") === -1 || element["@type"] !== "Gender") &&
                    (param.search("categories") === -1 || element["@type"] !== "Category") &&
                    (param.search(VariationFilter.lock.property) === -1 || element["@type"] !== VariationFilter.lock.type)) {

                    newParams += `${param}&`;
                }

                // but all the code above duplicates the urls :(
                // all these if conditions must be at the same level as the 'Lock' filter.
                // this will be fixed later
                if (param.search("categories") >= 0 && element["@type"] === "Category") {
                    let newCategories = [];
                    selectedFilters.filter(filter => filter["@type"] === "Category").forEach(category => {
                        newCategories.push(category.id);
                    });
                    newParams += `categories.id={${newCategories.toString()}}&`
                }

                if (param.search("gender") >= 0 && element["@type"] === "Gender") {
                    let newGenders = [];
                    selectedFilters.filter(filter => filter["@type"] === "Gender").forEach(gender => {
                        newGenders.push(`"${gender.id}"`);
                    });
                    newParams += `gender.id=[${newGenders}]&`;
                }

                if (param.search("brand") >= 0 && element["@type"] === "Brand") {
                    let newBrands = [];
                    selectedFilters.filter(filter => filter["@type"] === "Brand").forEach(brand => {
                        newBrands.push(`"${brand.id}"`);
                    });
                    newParams += `brand.id=[${newBrands}]&`;
                }

                if (param.search("option") >= 0 && element["@type"] === "RetailerOptionValue") {
                    let newOptions = [];
                    selectedFilters.filter(filter => filter["@type"] === "RetailerOptionValue").forEach(option => {
                        newOptions.push(`"${option.id}"`);
                    });
                    newParams += `options.id=[${newOptions}]&`;
                }

                return true;
            });

            if (element["@type"] === VariationFilter.lock.type) {
                const newLockFilter = selectedFilters
                    .filter(filter => filter["@type"] === VariationFilter.lock.type)
                    .map(lock => lock.value)
                ;

                newParams += `${VariationFilter.lock.property}=${newLockFilter}&`;
            }

            if (newParams.substr(newParams.length - 1) === "&") {
                newParams = newParams.substring(0, newParams.length - 1);
            }

            this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/variations/${encodeURIComponent(newParams)}`);
        }
    };

    handlePriceFilter = () => {
        const {minPriceFilter, maxPriceFilter, selectedFilters} = this.state;

        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let newParams = "";

        params.map((param) => {
            if (param.search("outletPrice") === -1 && param.search("between") === -1) {
                newParams += `${param}&`;
            }

            return true;
        });

        // string to number
        let minPrice = Number(minPriceFilter);
        let maxPrice = Number(maxPriceFilter);

        const newPriceFilter = {
            "@type": "Price",
            "minPrice": minPrice,
            "maxPrice": maxPrice
        };

        let newSelectedFilters = selectedFilters;

        // check if a price filter exist
        newSelectedFilters.forEach(element => {
            if (element["@type"] === "Price") {

                // remove price filter
                let index = newSelectedFilters.indexOf(element);
                newSelectedFilters.splice(index, 1);
                this.setState({selectedFilters: newSelectedFilters})
            }
        });

        // create price filter
        if (minPrice < maxPrice) {
            newParams += `outletPrice[between]=${newPriceFilter.minPrice}..${newPriceFilter.maxPrice}`;

            newPriceFilter.maxPrice = newPriceFilter.maxPrice.toLocaleString();
            newPriceFilter.minPrice = newPriceFilter.minPrice.toLocaleString();

            this.setState({selectedFilters: [...selectedFilters, newPriceFilter]});
            this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/variations/${encodeURIComponent(newParams)}`);
        }
    };

    handleChangeAction = event => {
        switch (event.target.value) {
            case "active":
                this.handleEditStatusToSelected(true);
                break;
            case "inactive":
                this.handleEditStatusToSelected(false);
                break;
            case "addCategories":
                this.handleDialog("addCategoriesDialog");
                break;
            case "deleteCategories":
                this.handleDialog('deleteCategoriesDialog');
                break;
            case "editGender":
                this.handleDialog("genderDialog");
                break;
            case "editBrand":
                this.handleDialog("brandDialog");
                break;
            case "deleteProducts":
                this.handleDialog('deleteProductsDialog');
                break;
            default:
        }
    };

    handleSelectAllClick = event => {
        const {retrieved} = this.props;
        if (event.target.checked) {
            let newDataArray = JSON.parse(JSON.stringify(retrieved["hydra:member"]));

            this.setState({
                selected: newDataArray,
                isSelectAllChecked: true,
                totalProductSelected: retrieved["hydra:member"].length
            });
        } else {
            this.setState({
                selected: [],
                isTotalSelected: false,
                isSelectAllChecked: false
            });
        }
    };

    handleSelectProduct = item => {
        const {selected} = this.state;

        let isItemAlreadyAdded = selected.some(itemAdded => itemAdded.id === item.id);

        if (!isItemAlreadyAdded) {
            this.setState({
                selected: [...selected, item]
            });
        } else {
            let newSelected = selected;

            for(let i = 0; i < newSelected.length; i++) {
                if (newSelected[i].id === item.id) {
                    newSelected.splice(i, 1);
                }
            }

            this.setState({
                selected: newSelected
            });
        }
    };

    handleCheckCategoryAdd = value => {
        const {categoriesToAdd} = this.state;
        const currentIndex = categoriesToAdd.indexOf(value);
        const newChecked = [...categoriesToAdd];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            categoriesToAdd: newChecked
        });
    };

    handleCheckCategoryDelete = value => {
        const {categoriesToDelete} = this.state;
        const currentIndex = categoriesToDelete.indexOf(value);
        const newChecked = [...categoriesToDelete];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            categoriesToDelete: newChecked
        });
    };

    handleEditStatusToSelected = (status) => {
        const params = {
            'variationList' : [],
            'key': ['status', status]
        };

        if (!this.state.isTotalSelected) {
            params['variationList'] = this.state.selected.map((item) => item['@id']);

            this.props.massUpdate(params);
        } else {
            this.setState({ loadingAllProduct: true });

            fetch("/variations?" + decodeURIComponent(this.props.match.params.params) + "&itemsPerPage=999999&page=1").then(
                response => {
                    response.json().then(retrieved => {
                        params['variationList'] = retrieved['hydra:member'].map((item) => item['@id']);

                        this.props.massUpdate(params);
                    });
                }
            );
        }

        this.setState({
            isTotalSelected: false,
            inputSelectClicked: false
        });

        this.resetSelectedAndCloseDialogAndRefreshList()
    };

    handleAddCategoriesToSelected = () => {
        const params = {
            'variationList' : [],
            'key': ['categoryAdd', this.state.categoriesToAdd]
        };

        if (!this.state.isTotalSelected) {
            params['variationList'] = this.state.selected.map((item) => item['@id']);

            this.props.massUpdate(params);
        } else {
            this.setState({ loadingAllProduct: true });

            fetch("/variations?" + decodeURIComponent(this.props.match.params.params) + "&itemsPerPage=999999&page=1").then(
                response => {
                    response.json().then(retrieved => {
                        params['variationList'] = retrieved['hydra:member'].map((item) => item['@id']);

                        this.props.massUpdate(params);
                    });
                }
            );
        }

        this.setState({ isTotalSelected: false });

        this.resetSelectedAndCloseDialogAndRefreshList("addCategoriesDialog")
    };

    handleDeleteCategoriesToSelected = () => {
        const params = {
            'variationList' : [],
            'key': ['categoryDelete', this.state.categoriesToDelete]
        };

        if (!this.state.isTotalSelected) {
            params['variationList'] = this.state.selected.map((item) => item['@id']);

            this.props.massUpdate(params);
        } else {
            this.setState({ loadingAllProduct: true });

            fetch("/variations?" + decodeURIComponent(this.props.match.params.params) + "&itemsPerPage=999999&page=1").then(
                response => {
                    response.json().then(retrieved => {
                        params['variationList'] = retrieved['hydra:member'].map((item) => item['@id']);

                        this.props.massUpdate(params);
                    });
                }
            );
        }

        this.setState({ isTotalSelected: false });

        this.resetSelectedAndCloseDialogAndRefreshList("deleteCategoriesDialog");
    };

    handleDeleteProductsToSelected = () => {
        const params = {
            'variationList' : [],
            'key': ['variationDelete']
        };

        if (!this.state.isTotalSelected) {
            params['variationList'] = this.state.selected.map((item) => item['@id']);

            this.props.massUpdate(params);
        } else {
            this.setState({ loadingAllProduct: true });

            fetch("/variations?" + decodeURIComponent(this.props.match.params.params) + "&itemsPerPage=999999&page=1").then(
                response => {
                    response.json().then(retrieved => {
                        params['variationList'] = retrieved['hydra:member'].map((item) => item['@id']);

                        this.props.massUpdate(params);
                    });
                }
            );
        }

        this.setState({ isTotalSelected: false });

        this.resetSelectedAndCloseDialogAndRefreshList("deleteProductsDialog");
    };

    handleEditGenderToSelected = (genderId) => {
        const params = {
            'variationList': [],
            'key': ['gender', genderId]
        };

        if (!this.state.isTotalSelected) {
            params['variationList'] = this.state.selected.map((item) => item['@id']);

            this.props.massUpdate(params);
        } else if (this.state.isTotalSelected && "" !== genderId.trim()) {
            this.setState({ loadingAllProduct: true });

            fetch("/variations?" + decodeURIComponent(this.props.match.params.params) + "&itemsPerPage=999999&page=1").then(
                response => {
                    response.json().then(retrieved => {
                        params['variationList'] = retrieved['hydra:member'].map((item) => item['@id']);

                        this.props.massUpdate(params);
                    });
                }
            );
        }

        this.setState({ isTotalSelected: false });

        this.resetSelectedAndCloseDialogAndRefreshList("genderDialog")
    };

    handleEditBrandToSelected = (brandId) => {
        const params = {
            'retailer': decodeURIComponent(this.props.match.params.id),
            'variationList': [],
            'key': ['brand', brandId]
        };

        if (!this.state.isTotalSelected) {
            params['variationList'] = this.state.selected.map((item) => item['@id']);

            this.props.massUpdate(params);
        } else if (this.state.isTotalSelected && "" !== brandId.trim()) {
            this.setState({ loadingAllProduct: true });

            fetch("/variations?" + decodeURIComponent(this.props.match.params.params) + "&itemsPerPage=999999&page=1").then(
                response => {
                    response.json().then(retrieved => {
                        params['variationList'] = retrieved['hydra:member'].map((item) => item['@id']);

                        this.props.massUpdate(params);
                    });
                }
            );
        }

        this.setState({ isTotalSelected: false });

        this.resetSelectedAndCloseDialogAndRefreshList("brandDialog")
    };

    handleDialog = (dialogName) => {
       if ('genderDialog' === dialogName && !this.state.allGendersFetched) {
            fetch(`/genders?pagination=false&organization.id=${this.props.currentOrganization}`).then(
                response => {
                    response.json().then(retrieved => {
                        this.setState({ allGenders: retrieved['hydra:member'], allGendersFetched: true })
                    });
                }
            );
        } else if ('brandDialog' === dialogName && !this.state.allBrandsFetched) {
            fetch(`/brands?pagination=false&organization.id=${this.props.currentOrganization}`).then(
                response => {
                    response.json().then(retrieved => {
                        this.setState({ allBrands: retrieved['hydra:member'], allBrandsFetched: true })
                    });
                }
            );
        } else if ('addCategoriesDialog' === dialogName && !this.state.allCategoriesFetched) {
            fetch(`/categories?pagination=false&organization.id=${this.props.currentOrganization}`).then(
                response => {
                    response.json().then(retrieved => {
                        this.setState({allCategories: retrieved['hydra:member'], allCategoriesFetched: true })
                    });
                }
            );
        }

        this.setState({
            [dialogName]: !this.state[dialogName],
            searchCategories: '',
            categoriesToAdd: []
        });
    };

    renderName = (variation) => {
        const {classes} = this.props;

        return (
            <div>
                <div>
                    <span className={classes.name}>{getTranslation(variation).name}</span>
                </div>
                <Hidden smDown>
                    <div className={classes.nameInfos}>
                        {variation.externalId &&
                        <div>
                            <span>
                                <FormattedMessage id={"retailer.product.list.label.id"}/> : {variation.externalId}
                            </span>
                        </div>
                        }

                        <div>
                            <span>
                                <FormattedMessage id={"retailer.product.list.label.sku"}/> : {variation.sku}
                            </span>
                        </div>

                        {variation.product.brand.name &&
                            <div>
                                <span>
                                    <FormattedMessage id={"retailer.product.list.header.brand"}/> : {variation.product.brand.name}
                                </span>
                            </div>
                        }

                        {variation.eshopId &&
                            <div>
                            <span>
                                <FormattedMessage id={"retailer.product.list.label.eshopid"}/> : {variation.eshopId}
                            </span>
                        </div>
                        }
                    </div>
                </Hidden>
            </div>
        );
    };

    renderCategories = (categories) => {
        const {classes} = this.props;

        return (
            <div>
                {categories.sort(sortOn("name")).map(category => {
                    return <Chip key={category.id} label={getTranslation(category).name} className={category.draft ? classes.categoryDraft : classes.category} />
                })}
            </div>
        )
    };

    renderStock = (stock, status) => {
        const {classes} = this.props;
        let stockType;

        if (stock > 5) {
            stockType = classes.inStock
        } else if (stock > 0) {
            stockType = classes.middleStock
        } else {
            stockType = classes.noStock
        }

        let statusChip =
            <Chip
                label={<FormattedMessage id={ status ? "retailer.product.list.label.active" : "retailer.product.list.label.inactive"}/>}
                className={status ? classes.status : classes.statusOff}
                variant="outlined"
                size="small"
            />;

        let stockChip =
            <Tooltip title={<FormattedMessage id={"retailer.product.list.label.stock"} values={{"stock": stock}}/>}>
                <Chip label={<FormattedMessage id={"retailer.product.list.label.stock"} values={{"stock": stock}}/>} className={stockType} size="small"/>
            </Tooltip>;

        return <div className={classes.stockStatus}>{statusChip}{stockChip}</div>;
    };

    renderOptions = (variation) => {
        const {classes} = this.props;

        return  (
            <div className={classes.stockStatus}>
                {Object.keys(variation.retailerOptionValues).map(function(index){

                    return (
                        <div>
                            <Tooltip title={`${getTranslation(variation.retailerOptionValues[index]['option'] ?? {translations: []})?.name} : ${getTranslation(variation.retailerOptionValues[index] ?? {translations: []})?.name}`}>
                                <span className={classes.options}>
                                    {getTranslation(variation.retailerOptionValues[index]['option'] ?? {translations: []})?.name} : {getTranslation(variation.retailerOptionValues[index] ?? {translations: []})?.name}
                                </span>
                            </Tooltip>
                        </div>
                    )
                })}
            </div>
        );
    }

    renderPrice = (price, outlet, discounted, currency) => {
        const {classes} = this.props;

        if (outlet || discounted) {
            return (
                <div>
                    <div className={classes.price}>
                        <FormattedNumberWithCurrency value={(discounted ? discounted : outlet)} currency={this.props.currentOrganization?.currency} />
                    </div>
                    <div className={classes.crossedPrice}>
                        <FormattedNumberWithCurrency value={price} currency={this.props.currentOrganization?.currency} />
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className={classes.price}>
                        <FormattedNumberWithCurrency value={price} currency={this.props.currentOrganization?.currency} />
                    </div>

                </div>
            )
        }
    };

    renderSelectTotal() {
        const {classes, retrieved} = this.props;
        const {isTotalSelected, itemsPerPage, totalProductSelected} = this.state;

        return (
            <GridContainer className={classes.selectTotalContainer}>
                <FormattedMessage
                    id={isTotalSelected ? "retailer.product.list.totalProductSelected" : "retailer.product.list.selectedProduct"}
                    values={{nb: totalProductSelected}}
                />
                <MaterialUiButton
                    color="primary"
                    className={classes.selectTotalButton}
                    onClick={(event) => {
                        event.stopPropagation();

                        if (!isTotalSelected) {
                            this.setState({
                                isTotalSelected: true,
                                totalProductSelected: retrieved["hydra:totalItems"]
                            })
                        } else {
                            this.setState({
                                isTotalSelected: false,
                                selected: [],
                                isSelectAllChecked: false,
                                totalProductSelected: parseInt(itemsPerPage)
                            })
                        }
                    }}
                >
                    {isTotalSelected ?
                        <FormattedMessage id={"retailer.product.list.cancelSelect"} />
                        :
                        <FormattedMessage
                            id={"retailer.product.list.selectTotal"}
                            values={{nb: retrieved["hydra:totalItems"]}}
                        />
                    }
                </MaterialUiButton>
            </GridContainer>
        )
    }

    // duplicate in filterMenu.js
    renderFilterMenu = () => {
        const {classes, brandRetrieved, categoryRetrieved, genderRetrieved, offer, intl} = this.props;
        const {retailerOptionValuesList} = this.state;
        const {filterMenuSelected} = this.state;

        if (null === retailerOptionValuesList) {
            return;
        }

        const statusRetrieved = [
            {"@type": "Status", "id": "actif", "name": intl.formatMessage({id:'retailer.product.list.filter.status.yes'}), "value": true},
            {"@type": "Status", "id": "inactif", "name": intl.formatMessage({id:'retailer.product.list.filter.status.no'}), "value": false}
        ];

        const stockRetrieved = [
            {"@type": "Stock", "id": "full", "name": intl.formatMessage({id:'retailer.product.list.filter.stocks.in'}), "value": "full"},
            {"@type": "Stock", "id": "out", "name": intl.formatMessage({id:'retailer.product.list.filter.stocks.out'}), "value": "out"},
            {"@type": "Stock", "id": "middle", "name": intl.formatMessage({id:'retailer.product.list.filter.stocks.middle'}), "value": "middle"},
        ];

        const validatedRetrieved = [
            {"@type": "Validated", "id": "validated", "name": intl.formatMessage({id:'retailer.product.list.filter.validated.yes'}), "value": true},
            {"@type": "Validated", "id": "noValidated", "name": intl.formatMessage({id:'retailer.product.list.filter.validated.no'}), "value": false},
        ];

        const eshopIdRetrieved = [
            {"@type": "EshopId", "id": "withEshopId", "name": intl.formatMessage({id:'retailer.product.list.filter.eshopid.yes'}), "value": true},
            {"@type": "EshopId", "id": "withoutEshopId", "name": intl.formatMessage({id:'retailer.product.list.filter.eshopid.no'}), "value": false},
        ];

        const categoryRetrievedSorted = categoryRetrieved ? categoryRetrieved.sort(sortOn("name")) : false;
        const brandRetrievedSorted = brandRetrieved ? brandRetrieved.sort(sortOn("name")) : false;

        let filters={};

        if (statusRetrieved && statusRetrieved.length > 0) {
            let filtersSource;

            // we don't display status / status when it is Stock Easy
            if (stockEasyOffer === offer) {
                filtersSource = {
                    "StatusStock": [{"Validated": validatedRetrieved}, {"Stocks": stockRetrieved}],
                }
            } else {
                filtersSource = {
                    "StatusStock": [{"Status": statusRetrieved}, {"Validated": validatedRetrieved}, {'EshopId': eshopIdRetrieved}, {"Stocks": stockRetrieved}],
                }
            }

            Object.assign(filters, filtersSource);
        }

        const lockFilter = [];

        for (let [key, value] of Object.entries(VariationFilter.lock.values)) {
            lockFilter.push({
                "@type":  VariationFilter.lock.type,
                "id": value,
                "name": intl.formatMessage({id: `retailer.product.list.filter.lock.${value}`}),
                "value": value
            });
        }

        Object.assign(filters,  {"Brand": brandRetrievedSorted});
        Object.assign(filters,  {"Gender": genderRetrieved && genderRetrieved.length > 0 ? genderRetrieved : null});
        Object.assign(filters,  {"Category": categoryRetrievedSorted});
        Object.keys(retailerOptionValuesList).forEach(function(optionName){
            Object.assign(filters, {[optionName]: retailerOptionValuesList[optionName]});
        });
        Object.assign(filters,  {"Price": 0});
        Object.assign(filters, {[VariationFilter.lock.type]: lockFilter});

        return (
            <div>
                <div className={classes.filterMenu}>
                    <Tabs
                        variant="scrollable"
                        value={Object.keys(filters).indexOf(filterMenuSelected)}
                        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                    >
                        {Object.keys(filters).map(name => {
                            return (
                                <Tab
                                    key={name}
                                    onClick={
                                        () => {
                                            this.setState({filterMenuSelected: name});
                                            this.handleTabFilterChange(name);
                                        }
                                    }
                                    disableRipple
                                    classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                    label={<FormattedMessage id={"retailer.product.list.filter." + name.toLowerCase()} defaultMessage={name}/>}
                                />
                            )
                        })}
                    </Tabs>
                </div>
                <div className={classes.filters}>
                    {this.renderFilterElement(filters)}
                </div>
            </div>
        );
    };

    handleTabFilterChange = value => {
        let retailer = decodeURIComponent(this.props.match.params.id);

        if ('Brand' === value && !this.props.brandRetrieved) {
            this.props.brandList(`pagination=false&onlyExistingProducts=true&retailer=${retailer.split("/retailers/").pop()}`);
        } else if ('Gender' === value && !this.props.genderRetrieved) {
            this.props.genderList(`pagination=false&onlyExistingProducts=true&retailer=${retailer.split("/retailers/").pop()}`);
        } else if ('Category' === value && !this.props.categoryRetrieved) {
            this.props.categoryList(`pagination=false&onlyExistingProducts=true&retailer=${retailer.split("/retailers/").pop()}`);
        }
    }

    renderFilterElement = (filters) => {
        const {classes} = this.props;
        const {filterMenuSelected} = this.state;

        if (filterMenuSelected === "Price") {
            return this.renderFilterPriceElement();
        } else if (filterMenuSelected === "StatusStock") {
            return this.renderFilterStatusStockElement(filters[filterMenuSelected]);
        } else if (!filters[filterMenuSelected]) {
            return (
                <div className={classes.filterLoading}>
                    <BeatLoader
                        sizeUnit={"px"}
                        size={12}
                        color={primaryColor}
                        loading={true}
                    />
                </div>
            )
        } else if (filterMenuSelected === "Category") {
            return this.renderFilterCategoriesContainer();
        } else if (filters[filterMenuSelected]) {
            if (VariationFilter.lock.type === filterMenuSelected) {
                // no sort
                filters[filterMenuSelected] = filters[filterMenuSelected]
            } else {
                filters[filterMenuSelected].sort(sortOn("value"))
            }

            return filters[filterMenuSelected].map(element => {
                let name = '';
                if (['Brand', VariationFilter.lock.type].includes(filterMenuSelected)) {
                    name = element.name;
                } else if ('Gender' === filterMenuSelected) {
                    name = getTranslation(element).value;
                } else {
                    name = getTranslation(element).name;
                }
                return <Chip
                    label={name}
                    key={element.id}
                    onClick={() => this.handleAddSelectedFilter(element)}
                    variant={this.checkIfFilterExist(element) ? "default" : "outlined"}
                    classes={{root: classes.filterItem}}
                />
            })
        }
    };

    renderFilterStatusStockElement = (stockStatusFilter) => {
        const {classes} = this.props;

        return (
            <div className={classes.statusStockFilter}>
                {stockStatusFilter.map(filter => {
                    let filterTitle = Object.keys(filter)[0];

                    return (
                        <div className={classes.filterInColumn} key={filterTitle}>
                            <span className={classes.filterBlueTitle}>
                                {this.props.intl.formatMessage({id: "retailer.product.list.filter." + filterTitle.toLowerCase()})}
                            </span>
                            {filter[filterTitle].map(element => {
                                return <Chip
                                    label={element.name || element.value}
                                    key={element.id}
                                    onClick={() => this.handleAddSelectedFilter(element)}
                                    variant={this.checkIfFilterExist(element) ? "default" : "outlined"}
                                    classes={{root: classes.filterItem}}
                                />
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };

    getCategoryChildren = (parentId, childToFindId, indexChildToDelete = -1) => {
        const {categoryRetrieved} = this.props;

        categoryRetrieved && categoryRetrieved.filter(cat => cat.parent && cat.parent.id === parentId).forEach(child => {
            if (child.id === childToFindId) {
                indexChildToDelete = this.state.selectedFilters.indexOf(child);
            }
            else {
                indexChildToDelete = this.getCategoryChildren(child.id, childToFindId, indexChildToDelete);
            }
        });

        return indexChildToDelete;
    };

    handleAddCategorySelectedFilter = (category, index) => {
        const {selectedFilters} = this.state;
        let indexToDelete = [];

        selectedFilters.forEach(filter => {
            // if selected fitlers list contain the parent of the category clicked
            // delete the parent from filter and replace by the category clicked
            if (category.parent && category.parent.id === filter.id) {
                let indexParentToDelete = selectedFilters.indexOf(filter);

                if (indexParentToDelete > -1) {
                    indexToDelete.push(indexParentToDelete);
                }
            }

            // if selected filters list contain children
            // find if theses children are children of the category clicked
            // and then delete them and replace by the category clicked
            else {
                if (filter.parent && filter.parent !== null ) {
                    let indexChildToDelete;

                    // if the filter is a child of the category clicked
                    if (filter.parent.id === category.id) {
                        indexChildToDelete = selectedFilters.indexOf(filter);
                    } else {
                        // get children of the categoruy until match with the filter
                        indexChildToDelete = this.getCategoryChildren(category.id, filter.id);
                    }

                    if (indexChildToDelete > -1) {
                        indexToDelete.push(indexChildToDelete);
                    }
                }
            }
        });

        // get all index of filters and delete them
        for (let i = indexToDelete.length -1; i >= 0; i--) {
            selectedFilters.splice(indexToDelete[i],1);
        }

        this.setState({selectedFilters}, () => {
            this.handleAddSelectedFilter(category);
            this.handleShowChildrenFilterColumn(category.id, index)
        });
    };

    handleShowChildrenFilterColumn = (id,index) => {
        const {childrenColumns} = this.state;

        childrenColumns[index] = id;

        if (this.hasChildren(id) && childrenColumns[index + 1] !== null) {
            childrenColumns.length = index +1
        } else {
            childrenColumns.length = index;
        }
        this.setState({childrenColumns});
    };

    renderFilterCategoriesElement = (childs,index) => {
        const {classes} = this.props;

        return childs.map(category => {
            return (
                <Chip
                    label={getTranslation(category).name}
                    key={category.id}
                    onClick={() => this.handleAddCategorySelectedFilter(category, index + 1)}
                    variant={this.checkIfFilterExist(category) ? "default" : "outlined"}
                    classes={{root: classes.filterItem}}
                />
            )
        })
    };

    getCategoryParent = (category) => {
        if (category.parent) {
            return this.getCategoryParent(category.parent);
        } else {
            return category;
        }
    }

    renderFilterCategoriesContainer = () => {
        const {classes,categoryRetrieved} = this.props;

        let categoryParentsFromRetrieved = [];
        let categoryParents = [];
        let alreadyListedCategory = [];
        if (categoryRetrieved) {
            categoryRetrieved.forEach(category => {
                let parent = this.getCategoryParent(category);
                categoryParentsFromRetrieved.push(parent);
            });
            categoryParentsFromRetrieved.forEach(element => {
                if (alreadyListedCategory.indexOf(element.id) < 0) {
                    categoryParents.push(element);
                    alreadyListedCategory.push(element.id);
                }
            });
        }

        return (
            <div className={classes.categoriesFilterContainer}>
               <div className={classes.categoriesFilterColumn}>
                    {categoryRetrieved && categoryParents.map(category => {
                        return (
                            <Chip
                                label={getTranslation(category).name}
                                key={category.id}
                                onClick={() => this.handleAddCategorySelectedFilter(category, 0)}
                                variant={this.checkIfFilterExist(category) ? "default" : "outlined"}
                                classes={{root: classes.filterItem}}
                            />
                        )
                    })}
                </div>

                {categoryRetrieved && this.state.childrenColumns.map((id,index) => {
                    return(
                        <div key={id} className={classes.categoriesFilterColumn}>
                            {this.renderFilterCategoriesElement(categoryRetrieved.filter(cat => cat.parent && cat.parent.id === id), index)}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderFilterPriceElement = () => {
        const {minPriceFilter, maxPriceFilter} = this.state;
        const {classes} = this.props;

        return (
            <div className={classes.priceFilter}>
                <div>
                    <TextField
                        type="number"
                        value={minPriceFilter}
                        onChange={(event) => this.setState({minPriceFilter: event.target.value})}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                    />
                    <span className={classes.priceFilterTo}>à</span>
                    <TextField
                        type="number"
                        value={maxPriceFilter}
                        onChange={(event) => this.setState({maxPriceFilter: event.target.value})}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                    />
                    <Button color={"info"} onClick={() => this.handlePriceFilter()}
                            className={classes.priceFilterSearch}>
                        <FormattedMessage id={"retailer.product.list.filter.price.search"}/>
                    </Button>
                </div>
            </div>
        );
    }

    renderSelectedFilter = () => {
        const {selectedFilters} = this.state;
        const {classes} = this.props;

        return (
            <div className={classes.filterSelectedContainer}>
                {selectedFilters.map(element => {
                    if (element["@type"] === "Price") {
                        return <Chip
                            key={"Price"}
                            label={<FormattedMessage
                                id={"retailer.product.list.filter.selected.price"}
                                values={{min: element.minPrice, max: element.maxPrice, currency: this.props.currentOrganization?.currency}}/>
                            }
                            color="primary"
                            onDelete={() => this.handleDeleteSelectedFilter(element)}
                            className={classes.filterSelectedItem}
                        />
                    } else {
                        let name = element.name;
                        if (!element.name && 'Gender' === element["@type"]) {
                            name = getTranslation(element).value;
                        } else if (!element.name) {
                            name = getTranslation(element).name;
                        }

                        return <Chip
                            key={element.id}
                            label={name}
                            color="primary"
                            onDelete={() => this.handleDeleteSelectedFilter(element)}
                            className={classes.filterSelectedItem}
                        />
                    }
                })}
            </div>
        );
    };

    renderResetButton = () => {
        const {classes} = this.props;
        return (
            <div align="right" className={classes.resetBar}>
                <Button
                    color={"info"}
                    onClick={() => this.setState({selectedFilters: []}, () => this.handleRouteReset())}
                    className={classes.resetSelected}
                >
                    <FormattedMessage id={"retailer.product.list.filter.selected.reset"}/>
                </Button>
            </div>
        );
    };

    renderActions = () => {
        const {classes} = this.props;

        return (
            <div className={classes.actionsContainer}>
                {this.renderSelectableActions()}
                {this.renderAddCategories()}
                {this.props.categoryRetrieved && this.renderDeleteCategories()}
                {this.renderEditGender()}
                {this.renderEditBrand()}
                {this.renderDeleteProducts()}
                {this.renderExportButton()}
            </div>
        );
    };

    renderExportButton() {
        const { classes, exportLoading, exportSuccess } = this.props;

        const handleSubmit = () => {
            this.props.export(this.props.retailer, this.props.match.params.params)
        };

        if (this.props.loading || UserAgentUtils.isMobile()) {
            return null;
        }

        if (exportLoading) {
            return (
                <BeatLoader
                    sizeUnit={"px"}
                    size={12}
                    color={primaryColor}
                    loading={true}
                />
            )
        }

        return <>
            <Tooltip title={this.props.intl.formatMessage({id: "retailer.product.list.export"})}>
                <IconButton
                    className={classes.exportButton}
                    onClick={handleSubmit}
                    color="primary"
                    size="large">
                    <GetApp/>
                </IconButton>
            </Tooltip>
            {exportSuccess && <FormattedMessage id={"retailer.product.list.export.success"} />}
        </>;
    }

    renderSelectableActions = () => {
        if (UserAgentUtils.isMobile()) {
            return;
        }

        const {classes} = this.props;
        const {selected, action} = this.state;

        const isActionsDisable = this.state.selected.length < 1 || this.props.massUpdateLoading || this.state.loadingAllProduct;

        if (this.props.offer === stockEasyOffer) {
            const tooltip = isActionsDisable ?
                'retailer.product.list.action.mass_product_status_toggle_disable' :
                'retailer.product.list.action.mass_product_status_toggle_explanation';
            return (
                <div className={classes.disableElementsContainer}>
                    <span className={classes.myOffers}>
                        <FormattedMessage  id={"retailer.product.list.action.products"} />
                    </span>
                    <Tooltip title={<FormattedMessage id={tooltip}/>}>
                        <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                            <Button
                                color={"danger"}
                                type="submit"
                                disabled={isActionsDisable}
                                onClick={() => this.handleEditStatusToSelected(false)}
                            >
                                <FormattedMessage id={"retailer.product.list.action.stockeasy.disable"} />
                            </Button>

                            <Button
                                color={"success"}
                                type="submit"
                                disabled={isActionsDisable}
                                onClick={() => this.handleEditStatusToSelected(true)}
                            >
                                <FormattedMessage id={"retailer.product.list.action.stockeasy.activate"} />
                            </Button>
                        </ButtonGroup>
                    </Tooltip>
                </div>
            );
        } else {
            return (
                <FormControl
                    variant="outlined"
                    disabled={this.props.authorizations && hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT) && selected.length > 0}
                    className={classes.actionSelect}
                >
                    <Select
                        variant="standard"
                        value={action}
                        onChange={this.handleChangeAction}
                        input={<OutlinedInput value="actions" name="actions"/>}
                        IconComponent={UnfoldMore}
                        displayEmpty={true}
                        className={classes.customSelectActions}
                        autoWidth={true}
                        disabled={this.state.selected.length < 1 || this.props.massUpdateLoading || this.state.loadingAllProduct}>
                        <MenuItem value="" disabled>Actions</MenuItem>
                        <MenuItem value={"active"}>
                            <FormattedMessage id={"retailer.product.list.action.offer.activate"}/>
                        </MenuItem>
                        <MenuItem value={"inactive"}>
                            <FormattedMessage id={"retailer.product.list.action.offer.disable"}/>
                        </MenuItem>
                        {this.renderMenuItems("addCategories","retailer.product.list.action.add.categories","retailer.product.list.action.add.categories.empty")}
                        {this.renderMenuItems("deleteCategories","retailer.product.list.action.delete.categories","retailer.product.list.action.add.categories.empty")}
                        {this.renderMenuItems("editGender","retailer.product.list.action.gender","retailer.product.list.action.gender.empty")}
                        {this.renderMenuItems("editBrand","retailer.product.list.action.brand","retailer.product.list.action.brand.empty")}
                        {hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT) &&
                        this.renderMenuItems("deleteProducts", "retailer.product.list.action.delete.variations", this.state.selected, "retailer.product.list.action.delete.variations")}
                    </Select>
                </FormControl>
            );
        }
    };

    renderMenuItems = (value, message, tooltip) => {
        return <MenuItem value={value}>
            <FormattedMessage id={message}/>
        </MenuItem>
    };

    handleShowChildren = (categoryId) => {
        const {showChildren} = this.state;

        showChildren[categoryId] = !showChildren[categoryId];

        this.setState({showChildren});
    };

    handleDeleteSelectedCategory = (category) => {
        const {categoriesToAdd} = this.state;

        let index = categoriesToAdd.indexOf(category);

        categoriesToAdd.splice(index, 1);
        this.setState({categoriesToAdd});
    };

    hasChildren = (parentId) => {
        const {categoryRetrieved} = this.props;

        if (categoryRetrieved && categoryRetrieved.filter(cat => cat.parent && cat.parent.id === parentId).length > 0) {
            return true;
        }
    };

    renderCategoryChildren = (categories, checkChildren = false) => {
        const {categoryRetrieved, classes} = this.props;
        const {categoriesToAdd, showChildren} = this.state;

        if (categoryRetrieved && categories.length > 0) {
            return categories.map(category => {
                let hasChildren = this.hasChildren(category.id);
                let isChecked = categoriesToAdd.indexOf(category) !== -1 || checkChildren;

                return (
                    <div key={category.id}>
                        <ListItem  className={classes.categorieListItem} button={hasChildren}>
                            <Checkbox
                                color="primary"
                                onChange={() => this.handleCheckCategoryAdd(category, categoriesToAdd)}
                                checked={isChecked}
                                classes={{checked: classes.checked}}
                                disabled={hasChildren}
                            />
                            <ListItemText
                                className={classes.categoryNameListItem + ' '+((showChildren[category.id] || categoriesToAdd.indexOf(category) !== -1) ? classes.categoryNameListItemSelected : null)}
                                primary={category.name}
                                onClick={() => hasChildren ? this.handleShowChildren(category.id) : this.handleCheckCategoryAdd(category, categoriesToAdd)}
                            />
                            {hasChildren && (showChildren[category.id] ? <KeyboardArrowDown/> : <KeyboardArrowRight/>)}
                        </ListItem>
                        <Collapse className={classes.childrenCategories} in={hasChildren && showChildren[category.id]} timeout="auto" unmountOnExit>
                            {this.renderCategoryChildren(categoryRetrieved.filter(cat => cat.parent !== null && cat.parent.id === category.id))}
                        </Collapse>
                    </div>
                );
            })
        } else {
            return <div className={classes.tableCellNoResult}><FormattedMessage id={"retailer.list.table.no_result"}/></div>
        }
    };

    renderAddCategories = () => {
        const {classes} = this.props;
        const {categoriesToAdd, addCategoriesDialog, searchCategories, allCategories, allCategoriesFetched} = this.state;

        return (
            <Dialog
                scroll="paper"
                open={addCategoriesDialog}
                onClose={() => {this.handleDialog('addCategoriesDialog');}}
                className={classes.dialog}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.dialog.title.add.categories"}/>
                </DialogTitle>
                <Divider/>
                <DialogContent className={classes.dialogContent}>
                    { allCategoriesFetched ? (
                        <>
                            <Input
                                placeholder={this.props.intl.formatMessage({id: "retailer.product.list.action.add.categories.find"})}
                                onChange={(e) => this.setState({searchCategories: e.target.value})}
                                className={classes.dialogSearch}
                                autoFocus
                                disableUnderline
                            />
                            <Divider/>
                            <List disablePadding className={classes.dialogContentChild}>
                                {searchCategories.length > 0 ?
                                    this.renderCategoryChildren(allCategories.filter(cat => cat.name.toLowerCase().includes(searchCategories)))
                                :
                                    this.renderCategoryChildren(allCategories.filter(cat => cat.parent === null))
                                }
                            </List>
                        </>
                    ) : (
                        <div className={classes.dialogContentLoader}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={primaryColor}
                                loading={true}
                            />
                        </div>
                    )}
                </DialogContent>
                { allCategoriesFetched && (
                    <>
                        <Divider/>
                        <div>
                            {categoriesToAdd.length > 0 && categoriesToAdd.map(category => {
                                return <Chip
                                    key={category.id}
                                    label={category.name || category.value}
                                    color="primary"
                                    onDelete={() => this.handleDeleteSelectedCategory(category)}
                                    className={classes.filterSelectedItem}
                                />
                              })}
                        </div>
                        <Divider/>
                        <DialogActions>
                            <Button color={"info"} simple onClick={() => this.handleDialog('addCategoriesDialog')} >
                                <ArrowBackIos/>
                                <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                            </Button>
                            <Button
                                round
                                onClick={() => this.handleAddCategoriesToSelected()}
                                color="primary"
                            >
                                <FormattedMessage id={"retailer.product.list.dialog.add"}/>
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        );
    };

    renderDeleteProducts = () => {
        const {classes} = this.props;
        const {deleteProductsDialog} = this.state;

        return (
            <Dialog
                scroll="paper"
                open={deleteProductsDialog}
                onClose={() => this.handleDialog('deleteProductsDialog')}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.delete.variations"}/>
                </DialogTitle>
                <Divider/>
                <DialogContent className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.delete.variations.notice"}/>
                </DialogContent>
                <DialogActions>
                    <Button color={"info"} simple onClick={() => this.handleDialog('deleteProductsDialog')}>
                        <ArrowBackIos/>
                        <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                    </Button>
                    <Button
                        round
                        onClick={() => this.handleDeleteProductsToSelected()}
                        color="danger"
                    >
                        <Delete/>
                        <FormattedMessage id={"retailer.product.list.dialog.action.delete"}/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    renderDeleteCategories = () => {
        const {classes} = this.props;
        const {categoriesToDelete, deleteCategoriesDialog, selected} = this.state;

        return (
            <Dialog
                scroll="paper"
                open={deleteCategoriesDialog}
                onClose={() => this.handleDialog('deleteCategoriesDialog')}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.delete.categories"}/>
                </DialogTitle>
                <Divider/>
                <DialogContent className={classes.dialogTitle}>
                    <List disablePadding>
                        {this.getCategoriesByVariations(selected).map(cat => {
                            return(
                                <ListItem key={cat.id} className={classes.categorieListItem}>
                                    <Checkbox
                                        color="primary"
                                        onChange={() => this.handleCheckCategoryDelete(cat,categoriesToDelete)}
                                        checked={categoriesToDelete.indexOf(cat) === -1}
                                        classes={{checked: classes.checked}}
                                    />
                                    <ListItemText primary={getTranslation(cat).name} />
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color={"info"} simple onClick={() => this.handleDialog('deleteCategoriesDialog')}>
                        <ArrowBackIos/>
                        <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                    </Button>
                    <Button
                        disabled={!categoriesToDelete.length}
                        round
                        onClick={() => this.handleDeleteCategoriesToSelected()}
                        color="primary"
                    >
                        <FormattedMessage id={"retailer.product.list.dialog.submit"}/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    renderEditGender = () => {
        const {classes} = this.props;
        const {genderDialog,genderSelected,allGendersFetched} = this.state;
        const genderRetrieved = this.state.allGenders;

        return (
            <Dialog
                open={genderDialog}
                scroll="paper"
                onClose={() => this.handleDialog("genderDialog")}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.gender"}/>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    { allGendersFetched ? (
                        <Select
                            value={genderSelected.length > 0 ? genderSelected : (genderRetrieved.length > 0 ? genderRetrieved[0]['@id'] : '')}
                            onChange={(event) => this.setState({genderSelected: event.target.value})}
                            input={<InputBase value={this.state.genderSelected}/>}
                            IconComponent={UnfoldMore}
                            className={classes.inviteSelectField}
                            variant="outlined"
                        >
                            {genderRetrieved.map(item => {
                                return <MenuItem key={item.id}
                                                 value={item['@id']}>{getTranslation(item).value}</MenuItem>
                            })}
                        </Select>
                    ) : (
                        <div className={classes.dialogContentLoader}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={primaryColor}
                                loading={true}
                            />
                        </div>
                    )}
                </DialogContent>
                { allGendersFetched && (
                    <DialogActions>
                        <Button color={"info"} simple onClick={() => this.handleDialog('genderDialog')}>
                            <ArrowBackIos/>
                            <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                        </Button>
                        <Button
                            round
                            onClick={() => this.handleEditGenderToSelected(genderSelected)}
                            color="primary"
                        >
                            <FormattedMessage id={"retailer.product.list.dialog.submit"}/>
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    };

    renderEditBrand = () => {
        const {classes} = this.props;
        const {brandDialog,brandSelected, searchBrand, allBrandsFetched} = this.state;
        const brandRetrieved = this.state.allBrands;

        let brandArray =  searchBrand.length > 0 ? brandRetrieved.filter(brand => brand.name.toLowerCase().includes(searchBrand)) : brandRetrieved;

        return (
            <Dialog
                open={brandDialog}
                scroll="paper"
                onClose={() => {
                    this.handleDialog("brandDialog");
                    this.setState({searchBrand: ''});
                }}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.brand"}/>
                </DialogTitle>
                <Divider/>
                <DialogContent className={classes.dialogContent}>
                    { allBrandsFetched ? (
                        <>
                            <Input
                                placeholder={this.props.intl.formatMessage({id: "retailer.product.list.action.brand.find"})}
                                onChange={(e) => this.setState({searchBrand: e.target.value})}
                                className={classes.dialogSearch}
                                autoFocus
                                disableUnderline
                            />
                            <Divider/>
                            {brandArray.length > 0 ?
                                <RadioGroup
                                    ref={null}
                                    aria-label="Brand"
                                    name="brand"
                                    value={brandSelected.length > 0 ? brandSelected : brandArray[0]["@id"]}
                                    onChange={(event) => this.setState({brandSelected: event.target.value}) }
                                    className={classes.dialogContentChild}
                                >
                                {brandArray.map(brand => (
                                    <FormControlLabel value={brand["@id"]} key={brand.id} control={<Radio color="primary" />} label={brand.name} />
                                ))}
                                </RadioGroup>
                                :
                                <div className={classes.tableCellNoResult}><FormattedMessage id={"retailer.list.table.no_result"}/></div>
                            }
                        </>
                    ) : (
                        <div className={classes.dialogContentLoader}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={primaryColor}
                                loading={true}
                            />
                        </div>
                    )}
                </DialogContent>
                { allBrandsFetched && (
                    <DialogActions>
                        <Button
                            simple
                            onClick={() => {
                                this.handleDialog("brandDialog");
                                this.setState({searchBrand: ''});
                            }}
                            color="info">
                            <ArrowBackIos/>
                            <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                        </Button>
                        <Button
                            round
                            onClick={() => this.handleEditBrandToSelected(brandSelected)}
                            color="primary"
                        >
                            <FormattedMessage id={"retailer.product.list.dialog.submit"}/>
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    };

    renderNotification = () => {
        const {massUpdateSuccess, massUpdateError} = this.props;
        const {showNotification} = this.state;

        if (massUpdateSuccess) {
            return (
                <Snackbar
                    open={showNotification}
                    close
                    closeNotification={() => this.setState({showNotification: false})}
                    place={"bl"}
                    color={"success"}
                    icon={function () {
                        return <NotificationsActive/>
                    }}
                    message={<FormattedMessage id={"retailer.product.list.notifications.list.updated"}/>}
                />
            );
        } else if (massUpdateError) {
            return (
                <Snackbar
                    open={showNotification}
                    close
                    closeNotification={() => this.setState({showNotification: false})}
                    place={"bl"}
                    color={"danger"}
                    icon={function () {
                        return <NotificationsActive/>
                    }}
                    message={massUpdateError}
                />
            );
        }
    };

    renderLoadingBar = () => {
        const {loading, massUpdateLoading, retrieved, classes} = this.props;

        return (
            <div>
                {!retrieved || massUpdateLoading ?
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={loading || massUpdateLoading}
                        />
                    </div> :
                    <div className={classes.beatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={loading || massUpdateLoading}
                        />
                    </div>
                }
            </div>
        )
    };

    isSelected = item => this.state.selected.some(selectedItem => selectedItem.id === item.id);

    getParameterNameAndPropertyValue = (params, paramName) => {
        let values = "";
        params = params.split("&");
        params.map((param) => {
            if (param.search(paramName) !== -1)
                values = param;
        });
        return values;
    };

    handleEditVariation = (variation, currentPage, currentOrderBy) => {
        if (window.getSelection().toString().length > 0) {
            return;
        }

        if (
            hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT)
            || hasAuthorization(this.props.authorizations, Authorizations.STOCK_EASY_ACCESS)
        ) {
            this.props.history.push({
            pathname:`${this.state.baseParamsUrl + this.props.match.params.id}/products/show/${variation.product.id}`,
            variation:variation,
            currentPage:currentPage,
            currentOrderBy:currentOrderBy,
            type:"variation",
        })}
    }

    render() {
        const {classes, retrieved, intl} = this.props;
        const {itemsPerPage, selectedFilters, hideFilter} = this.state;
        const currentPage = this.getParameterNameAndPropertyValue(decodeURIComponent(this.props.match.params.params), "page");
        const currentOrderBy = this.getParameterNameAndPropertyValue(decodeURIComponent(this.props.match.params.params), "order");

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <CardHeader color="info">
                                    <GridContainer>
                                        <GridItem xs={12} sm={8} className={classes.titleContainer}>
                                            <h3><FormattedMessage id={"retailer.product.list.variation.title"}/></h3>
                                            <Tooltip title={<FormattedMessage id={"retailer.product.icon.list.simple"}/>}>
                                                <ViewHeadline className={classes.showParentIcon} onClick={() => this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/${encodeURIComponent(`retailer.id=${decodeURIComponent(this.props.match.params.id).replace("/retailers/", "")}&order[id]=asc&itemsPerPage=50&page=1`)}`)}/>
                                            </Tooltip>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} className={classes.mainActionsContainer}>
                                            <TextField
                                                id="search-input"
                                                placeholder={intl.formatMessage({id: "retailer.product.list.filter.search.placeholder"})}
                                                onChange={(event) => this.handleSearch(event)}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (<Search color={"disabled"}/>),
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    {this.props.offer !== stockEasyOffer &&
                                        <div className={classes.filterBarContainer}>
                                            <FormattedMessage id={"retailer.product.list.filter.title"}/>
                                            <IconButton
                                                className={classes.filterButton}
                                                onClick={() => this.setState({hideFilter: !hideFilter})}
                                                size="large">
                                                {hideFilter ? <AddRounded/> : <RemoveRounded/>}
                                            </IconButton>
                                            <div hidden={hideFilter}>
                                                <div className={classes.filter}>
                                                    {this.renderFilterMenu()}
                                                    {selectedFilters.length > 0 && this.renderSelectedFilter()}
                                                    {selectedFilters.length > 0 && this.renderResetButton()}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} className={classes.action}>
                                            {(hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT) || hasAuthorization(this.props.authorizations, Authorizations.STOCK_EASY_ACCESS)) && this.renderActions()}
                                        </GridItem>
                                        <GridItem xs={12} sm={6} className={classes.totalContainer}>
                                            <span className={classes.totalSpan}>
                                                {retrieved ? intl.formatMessage({id: "retailer.product.list.label.totalProduct"}) + retrieved["hydra:totalItems"] : null}
                                            </span>
                                            <ItemsPerPageSelect
                                                prefixUrl={`${this.state.baseParamsUrl + this.props.match.params.id}/products/variations/`}
                                                history={this.props.history}
                                                match={this.props.match}
                                                defaultItemsPerPage={"50"}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <div className={classes.tableWrapper}>
                                        {this.state.isSelectAllChecked && this.state.selected.length === parseInt(itemsPerPage) &&
                                            this.renderSelectTotal()
                                        }
                                        <Table padding={retrieved && retrieved["hydra:member"].length > 0 ? "none" : "normal"}>
                                            <TableHead
                                                state={this.state}
                                                onRequestSort={this.handleRequestSort}
                                                onSelectAllClick={this.handleSelectAllClick}
                                                isSelectAllChecked={this.state.isSelectAllChecked}
                                                isRetrievedFinished={retrieved && retrieved["hydra:member"].length > 0}
                                                tableHead={[
                                                    {id: "product.id", numeric: false, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.article"}), sort: true},
                                                    {id: "translations.name", numeric: false, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.name"}), sort: true},
                                                    //{id: "categories", numeric: false, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.brand"}), sort: false, hideOnMobile: true},
                                                    {id: "updatedAt", numeric: true, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.updatedAt"}), sort: true, hideOnMobile: true},
                                                    {id: "outletPrice", numeric: true, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.price"}), sort: true, hideOnMobile: true},
                                                    {id: "stock", numeric: true, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.stock.status"}), sort: false, hideOnMobile: true},
                                                    {id: "variations", numeric: true, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.variations"}), sort: false, hideOnMobile: true},
                                                ]}
                                            />
                                            <TableBody>
                                                {!this.props.massUpdateLoading && (retrieved && retrieved["hydra:member"].length > 0) &&
                                                retrieved["hydra:member"].map(variation => {
                                                    const isSelected = this.isSelected(variation);
                                                    return (
                                                        <TableRow
                                                            onClick={() => this.handleEditVariation(variation, currentPage, currentOrderBy)}
                                                            hover
                                                            tabIndex={-1}
                                                            key={variation.id}
                                                            aria-checked={isSelected}
                                                            selected={isSelected}
                                                            classes={{hover: hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT) && classes.tableRow}}
                                                        >
                                                            {!UserAgentUtils.isMobile() && <TableCell>
                                                                <Checkbox
                                                                    checked={isSelected}
                                                                    classes={{
                                                                        root: classes.checkbox,
                                                                        checked: classes.checked
                                                                    }}
                                                                    color={"primary"}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.handleSelectProduct(variation)
                                                                    }}
                                                                />
                                                            </TableCell>}
                                                            <TableCell className={classes.imageCell}>
                                                                {variation.pictures.length > 0 ?
                                                                    <img
                                                                        alt="variation_image"
                                                                        src={getFirstProductImage(variation.pictures)}
                                                                        className={classes.image}
                                                                    /> :
                                                                    <ImageSharp color={"disabled"} className={classes.imagePlaceholder} />
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.renderName(variation)}
                                                            </TableCell>
                                                            {/*<Hidden xsDown>
                                                                <TableCell className={classes.categoryContainer}>
                                                                    {this.renderCategories(variation.product.categories)}
                                                                </TableCell>
                                                            </Hidden>*/}
                                                            <Hidden smDown>
                                                                <TableCell>
                                                                    <CustomDate showRelativeDate={true} date={variation.updatedAt} />
                                                                </TableCell>
                                                            </Hidden>
                                                            <Hidden smDown>
                                                                <TableCell>
                                                                    {this.renderPrice(variation.price, variation.outletPrice, variation.discountedPrice, variation.product.currency.code)}
                                                                </TableCell>
                                                            </Hidden>
                                                            <Hidden smDown>
                                                                <TableCell>
                                                                    {this.renderStock(variation.stock, variation.status)}
                                                                </TableCell>
                                                            </Hidden>
                                                            <Hidden smDown>
                                                                <TableCell>
                                                                    {this.renderOptions(variation)}
                                                                </TableCell>
                                                            </Hidden>
                                                        </TableRow>
                                                    );
                                                })
                                                }
                                                {retrieved && retrieved["hydra:member"].length === 0 &&
                                                <TableRow>
                                                    <TableCell colSpan={9} className={classes.tableCellNoResult}>
                                                        <FormattedMessage id={"feed.list.table.no_result"}/>
                                                    </TableCell>
                                                </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                </CardBody>
                                <CardFooter center>
                                    {!this.props.massUpdateLoading &&
                                        <PaginationContainer
                                            itemsPerPage={this.state.itemsPerPage}
                                            urlParams={this.props.match.params.params}
                                            retrieved={this.props.retrieved}
                                            prefix={`${this.state.baseParamsUrl + this.props.match.params.id}/products`}
                                        />
                                    }
                                </CardFooter>
                                {this.renderLoadingBar()}
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                {this.renderNotification()}
            </div>
        );
    }
};

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        retrieved: state.variation.list.retrieved,
        brandRetrieved: state.brand.list.retrieved,
        categoryRetrieved: state.category.list.retrieved,
        genderRetrieved: state.gender.list.retrieved,
        loading: state.variation.list.loading,
        exportLoading: state.variation.list.exportLoading,
        exportSuccess: state.variation.list.exportSuccess,
        massUpdateLoading : state.product.massUpdate.massUpdateLoading,
        massUpdateSuccess : state.product.massUpdate.massUpdateSuccess,
        massUpdateError: state.product.massUpdate.massUpdateError,
        member: state.authentication.member,
        authorizations: state.authentication.authorizations,
        itemsPerPage: state.itemsPerPage.itemsPerPage,
        retailer: state.retailer.show.retrieved,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };
};

const mapDispatchToProps = dispatch => ({
    list: (params) => dispatch(list(params)),
    export: (retailer, params) => dispatch(downloadExport(retailer, params)),
    reset: () => dispatch(reset()),
    brandList: params => dispatch(brandList(params)),
    categoryList: params => dispatch(categoryList(params)),
    genderList: params => dispatch(genderList(params)),
    massUpdate: (params, values) => dispatch(massUpdate(params, values)),
    massUpdateReset: eventSource => dispatch(massUpdateReset(eventSource)),
    retailerOptionValuesList: params => dispatch(retailerOptionValuesList(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(injectIntl(EnhancedTable)));
