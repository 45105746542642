import React, {useEffect, useState} from "react";
import {BeatLoader} from "react-spinners";
import {withStyles} from "@mui/styles";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {NotificationsActive} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";

// Core components
import CardHeader from "components/card/cardHeader";
import Card from "components/card/card";
import GridItem from "components/grid/gridItem";
import GridContainer from "components/grid/gridContainer";

import {QuoteContext} from "../../../../contexts/quoteContext";

import detailStyle from "../../../../assets/jss/views/quote/detailStyle";
import {primaryColor} from "../../../../assets/jss/main";
import Snackbar from "../../../../components/snackbar/snackbar";

// API
import list from "../../../../api/taxRates/list";
import {
    forceCustomerAcceptation,
    accept,
    acceptOffline,
    assign,
    detail,
    refuse,
    unlock,
} from "../../../../api/quote";

import {ACCEPTED, UPDATING_QUOTE, WAITING_CUSTOMER, WAITING_REVIEW} from "../../enums/state";

// Quote components
import QuoteDetailHeader from "./header/header";
import QuoteDetailGrid from "./grid/grid";
import QuoteDeleteLineModal from "./deleteLineModal";
import QuoteAddProductModal from "./addProductModal";
import GridHistoryContent from "./grid/gridHistoryContent";
import QuoteNotes from "./notes/notes";
import {useParams} from "react-router-dom";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        display: 'flex',
        width: '664px',
        padding: '24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: 0,
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.08)',
        [theme.breakpoints.down('md')]: {
            padding: '16px',
        },
    },
}));

const checkUpdateReportInterval = 3000;
const QuoteDetailContainer = ({ match, classes }) => {
    const [loading, setLoading] = useState(true);
    const [quote, setQuote] = useState(null);
    const [lineBeingEdited, setLineBeingEdited] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [openAddProductModal, setOpenAddProductModal] = useState(false);
    const [lineAskingForDeletion, setLineAskingForDeletion] = useState(null);
    const [lineAskingForDeletionErrorDisplayed, setLineAskingForDeletionErrorDisplayed] = useState(false);
    const [taxRates, setTaxRates] = useState([]);

    let params = useParams();
    const forceCustomerValidateQuote = () => {
        setSubmitting(true);
        forceCustomerAcceptation(match.params.id).then(() => refreshQuote((quote) => {
            if (quote.state === UPDATING_QUOTE) {
                setIsProcessing(true);
                setSubmitting(true);
                setTimeout(checkQuoteStateForCustomer, checkUpdateReportInterval);
                return;
            }

            if (quote.state === ACCEPTED) {
                setIsProcessing(false);
                setSubmitting(false);
                setQuote(quote);
            }
        }))
    }

    const validateQuote = () => {
        setSubmitting(true);
        accept(match.params.id).then(() => refreshQuote((quote) => {
            if (quote.state === UPDATING_QUOTE) {
                setIsProcessing(true);
                setSubmitting(true);
                setTimeout(checkQuoteState, checkUpdateReportInterval);
                return;
            }

            if (quote.state === WAITING_CUSTOMER || quote.state === WAITING_REVIEW) {
                setIsProcessing(false);
                setSubmitting(false);
                setQuote(quote);
            }
        }))
    }

    const validateOfflineQuote = () => {
        setSubmitting(true);
        acceptOffline(match.params.id).then(() => refreshQuote((quote) => {
            if (quote.state === UPDATING_QUOTE) {
                setIsProcessing(true);
                setSubmitting(true);
                setTimeout(checkQuoteState, checkUpdateReportInterval);
                return;
            }

            if (quote.state === WAITING_CUSTOMER || quote.state === WAITING_REVIEW) {
                setIsProcessing(false);
                setSubmitting(false);
                setQuote(quote);
            }
        }))
    }

    const unlockQuote = () => {
        setIsProcessing(true);
        unlock(match.params.id).then(() => refreshQuote()).finally(() => setIsProcessing(false));
    }

    const assignQuote = (callback) => {
        setSubmitting(true);
        assign(match.params.id).then(() => {
            refreshQuote(callback);
            setSubmitting(false);
        })
    }

    const refreshQuote = (callback) => {
        setLoading(true);
        detail(
            match.params.id
        ).then(quote => {
            setQuote(quote);
            if (quote.state === UPDATING_QUOTE) {
                setIsProcessing(true);
            }
            setLoading(false);

            if (typeof callback === 'function') {
                callback(quote);
            }
        });
    }

    const refuseQuote = () => {
        setSubmitting(true);
        refuse(match.params.id).then(() => {
            refreshQuote();
            setSubmitting(false);
        });
    }

    const openNewLineModal = () => {
        setOpenAddProductModal(true);
    }

    const openDeleteLineModal = (line) => {
        if (quote.lines.length <= 1) {
            setLineAskingForDeletionErrorDisplayed(true);
            return;
        }
        setLineAskingForDeletion(line);
    }

    const checkQuoteState = () => {
        detail(
            match.params.id
        ).then(quote => {
            if (quote.state === UPDATING_QUOTE) {
                setIsProcessing(true);
                setTimeout(checkQuoteState, checkUpdateReportInterval);
                return;
            }
            if (quote.state === WAITING_CUSTOMER || quote.state === WAITING_REVIEW) {
                setIsProcessing(false);
                setSubmitting(false);
                setQuote(quote);
            }
        });
    };

    const checkQuoteStateForCustomer = () => {
        detail(
            match.params.id
        ).then(quote => {
            if (quote.state === UPDATING_QUOTE) {
                setIsProcessing(true);
                setTimeout(checkQuoteState, checkUpdateReportInterval);
                return;
            }

            if (quote.state === ACCEPTED) {
                setIsProcessing(false);
                setSubmitting(false);
                setQuote(quote);
            }
        });
    };

    const initQuoteStateCheck = (quote) => {
        if (quote.state !== UPDATING_QUOTE) {
            return;
        }

        checkQuoteState();
    };

    // ComponentDidMount
    useEffect(() => {
        detail(
            match.params.id
        ).then(quote => {
            setQuote(quote);
            initQuoteStateCheck(quote);
            setLoading(false);
        });
        list().then(taxRates => {
            setTaxRates(taxRates);
        });
    }, [params]);

    if (loading) {
        return <div>
            <BeatLoader
                sizeUnit={"px"}
                size={12}
                color={primaryColor}
                loading={true}
            />
        </div>
    }

    return (
        <QuoteContext.Provider value={{
            quote,
            isProcessing,
            isSubmitting,
            forceCustomerValidateQuote,
            validateQuote,
            validateOfflineQuote,
            refuseQuote,
            unlockQuote,
            openNewLineModal,
            openDeleteLineModal,
            refreshQuote,
            assignQuote,
            lineBeingEdited,
            setLineBeingEdited,
            taxRates,
        }}>
            <GridContainer>
                <GridItem xs={12}>
                    <Card last>
                        <CardHeader
                            className={classes.cardHeader}
                            color="info"
                        >
                            <QuoteDetailHeader/>
                        </CardHeader>
                        <QuoteDetailGrid/>
                    </Card>
                </GridItem>
            </GridContainer>
            <QuoteNotes/>
            <GridHistoryContent/>

            <Snackbar
                open={lineAskingForDeletionErrorDisplayed}
                close
                closeNotification={() => setLineAskingForDeletionErrorDisplayed(false)}
                place={"bl"}
                color={"danger"}
                icon={function () {
                    return <NotificationsActive/>
                }}
                message={<FormattedMessage id={'picking.quote.detail.deleteLineModal.notAllow'} />}
            />

            <Dialog
                open={!!lineAskingForDeletion}
                onClose={() => setLineAskingForDeletion(null)}
                fullWidth
            >
                <QuoteDeleteLineModal
                    deleted={() => {
                        setLineAskingForDeletion(null);
                        refreshQuote();
                    }}
                    onCancel={() => setLineAskingForDeletion(null)}
                    line={lineAskingForDeletion}
                />
            </Dialog>

            <StyledDialog
                open={!!openAddProductModal}
                onClose={() => setOpenAddProductModal(false)}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(51, 51, 51, 0.20)' } } }}
                fullWidth
            >
                <QuoteAddProductModal onCancel={() => setOpenAddProductModal(false)}/>
            </StyledDialog>
        </QuoteContext.Provider>
    );
};

export default withStyles(detailStyle)(QuoteDetailContainer);
