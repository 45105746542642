import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl, FormattedHTMLMessage} from "react-intl";
import {connect} from "react-redux";
import {BeatLoader, PropagateLoader} from 'react-spinners';
import Snackbar from "components/snackbar/snackbar";
import 'react-medium-image-zoom/dist/styles.css';

// @mui/material components
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Menu,
    MenuItem,
    Button,
} from "@mui/material";

// @mui/icons-material
import {NotificationsActive} from "@mui/icons-material";

// core components
import CancellationReasonDialog from "../components/cancellationReasonDialog";
import FormattedNumberWithCurrency from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

// styles
import tableStyle from "assets/jss/views/picking/order/showTableStyle";
import {withStyles} from "@mui/styles";
import {primaryColor} from 'assets/jss/main';

// helpers
import {Authorizations, hasAuthorization} from "utils/authorizations";

// Actions
import {
    confirm,
    assign,
    reserve,
    updatePrice,
    confirmReturningRunnerToRetailer,
    confirmReturn,
    cancellationReason
} from "actions/lineItem/update";

// context
import {OrderContext} from "contexts/OrderContext";
import ShipmentList from "./components/ShipmentList";
import LineItemRow from "./components/LineItemRow";
import StyledTable from "../../../components/TableCells/StyledTable";
import StyledTableResponsive from "../../../components/TableCells/StyledTableResponsive";
import ShowTableHeader from "./components/showTableHeader";

class OrderTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            actionErrorNotification: true,
            showAssignButtonForLineItem: null,
            anchorEl: null,
            itemBeingEdited: null,
            updatePriceModalOpening: false,
            cancellationReasonDialog: false,
            transitionActions: null,
            selectedItem: null,
        };
    };

    static propTypes = {
        loading: PropTypes.bool.isRequired,
        deleteLoading: PropTypes.bool.isRequired,
        deleted: PropTypes.object
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!nextProps.tableData) {
            const lineItemQuantities = Object.fromEntries(new Map(
                nextProps.tableData.map(lineItem => [lineItem['@id'], {
                    loading: false,
                    price: lineItem.amount,
                }])
            ));

            this.setState({
                lineItemInfo: lineItemQuantities,
            });
        }
    }

    handleShowAssignButton(item) {
        const runnerIsTheUser = (`/members/${this.props.member["id"]}` === item.runner['@id']);

        if (!runnerIsTheUser) {
            this.setState({showAssignButtonForLineItem: item['@id']});
        }
    }

    changeLineItemPrice(item, price) {
        if (!item) {
            this.setState({updatePriceModalOpening: false});
            return;
        }

        if (!this.state.lineItemInfo.hasOwnProperty(item['@id'])) {
            console.warn(`there is no input with id ${item['@id']}`);
        }

        const lineQuantities = this.state.lineItemInfo;
        lineQuantities[item['@id']] = {
            price: price,
            loading: lineQuantities[item['@id']].loading,
        }
        this.setState({lineItemInfo: lineQuantities});
    }

    handleConfirm = (item) => {
        if (window.confirm(this.props.intl.formatMessage({id: "picking.order.show.table.window.confirm"}))) {
            this.props.confirm(item)
        }
    };

    handleReserve = (item) => {
        if (window.confirm(this.props.intl.formatMessage({id: "picking.order.show.table.window.reserve"}))) {
            this.props.reserve(item)
        }
    };

    handleConfirmReturnRunnerToRetailer = (item) => {
        if (window.confirm(this.props.intl.formatMessage({id: "picking.order.show.table.window.confirm.returning"}))) {
            this.props.confirmReturningRunnerToRetailer(item)
        }
    }

    handleConfirmReturn = (item) => {
        if (window.confirm(this.props.intl.formatMessage({id: "picking.order.show.table.window.confirm.return"}))) {
            this.props.confirmReturn(item)
        }
    }

    handleUpdateLinePrice(item) {
        if (!this.state.lineItemInfo.hasOwnProperty(item['@id'])) {
            console.warn(`there is no input with id ${item.id}`);
            return;
        }

        this.handleLineItemPriceModalClose();
        const price = this.state.lineItemInfo[item['@id']].price;
        if (price >= item.price || price < 0) {
            return;
        }

        this.state.lineItemInfo[item['@id']].loading = true;
        this.setState({lineItemInfo: this.state.lineItemInfo})
        this.props.updatePrice(item, price);
    }

    handleClickAssign(item, runner) {
        this.props.assign(item, {'runner': `/members/${runner}`})
    };

    confirmCancellationAction(reason, unavailableDays = null) {
        const {selectedItem} = this.state;

        this.props.cancellationReason(selectedItem, reason, unavailableDays);

        this.closeCancellationReasonDialog();
    }

    openCancellationDialogByTransitionAndItem(item, transitionActions) {
        this.setState({
            selectedItem: item,
            transitionActions: transitionActions,
            cancellationReasonDialog: true
        })
    }

    closeCancellationReasonDialog() {
        this.setState({
            cancellationReasonDialog: false,
            selectedItem: null,
            transitionActions: null
        })
    }

    renderAdjustments(adjustments, classes) {
        return adjustments.map((item, key) => {
            return <TableRow key={'adjustment'+key}>
                <TableCell className={classes.adjustment}/>
                <TableCell className={classes.adjustment}/>
                <TableCell className={classes.adjustment}>{item['label']}</TableCell>
                <TableCell className={classes.adjustment}/>
                <TableCell className={classes.adjustmentTotal}>
                    <b>
                        <FormattedNumberWithCurrency value={item['amount'] / 100} currency={this.props.currentOrganization?.currency} />
                    </b>
                </TableCell>
                <TableCell className={classes.adjustment}/>
                <TableCell className={classes.adjustment}/>
                <TableCell className={classes.adjustment}/>
                <TableCell className={classes.adjustment}/>
            </TableRow>;
        });
    }

    render() {
        const {
            classes,
            tableData,
            handleChangeCheckboxGlobal,
        } = this.props
        const hasErrors = this.props.error || this.props.updateErrorLineItem;
        const order = tableData && tableData.length && tableData[0].order || {};

        const hasNotShippedLineItems = tableData && tableData.length;
        const hasShipments = this.props.shipments && Object.keys(this.props.shipments).length;

        return (
            <OrderContext.Provider value={{
                closeCancellationReasonDialog: () => this.closeCancellationReasonDialog(),
                confirmCancellationAction: (selectedReason, daysCount) => {
                    this.confirmCancellationAction(selectedReason, daysCount)
                },
                isCancellationReasonDialogOpen: this.state.cancellationReasonDialog,
                transitionActions: this.state.transitionActions,
                isOrderAllowed: false,
                isItemAllowed: true,
                status: this.state.selectedItem?.status,
                currentOrganization: this.props.currentOrganization,
                authorizations: this.props.authorizations,
                member: this.props.member,
                updateLoadingLineItem: this.props.updateLoadingLineItem,
                updateLoadingOrder: this.props.updateLoadingOrder,
                openCancellationDialogByTransitionAndItem: (item, transitionAction) => this.openCancellationDialogByTransitionAndItem(item, transitionAction),
                handleReserve: (item) => this.handleReserve(item),
                handleConfirm: (item) => this.handleConfirm(item),
                handleConfirmReturnRunnerToRetailer: (item) => this.handleConfirmReturnRunnerToRetailer(item),
                handleConfirmReturn: (item) => this.handleConfirmReturn(item),
                setItemBeingEdited: (item) => this.setState({itemBeingEdited: item}),
                setEditMenuPosition: (currentTarget) => this.setState({anchorEl: currentTarget}),
                handleShowAssignButton: (item) => this.handleShowAssignButton(item),
                setShowAssignButtonForLineItem: (item) => this.setState({showAssignButtonForLineItem: item}),
                showAssignButtonForLineItem: this.state.showAssignButtonForLineItem,
                handleClickAssign: (item, runnerId) => this.handleClickAssign(item, runnerId),
                runnerHeader: this.props.runnerHeader,
                organizationShippingMethods: this.props.organizationShippingMethods,
                lineItemSelectedIds: this.props.lineItemSelectedIds,
                handleChangeCheckboxItem: this.props.handleChangeCheckboxItem,
                orderStatus: order.status,
                toShipLineItemLength: this.props.toShipLineItemLength,
                handleChangeCheckboxGlobal:() => handleChangeCheckboxGlobal(),
                refreshOrder: () => this.props.refreshOrder(),
                toggleModalWaybillSelector: () => this.props.toggleModalWaybillSelector(),
                setLineItemSelectedIds: (lineItemIds) => this.props.setLineItemSelectedIds(lineItemIds),
                setSelectedShipment: (shipment) => this.props.setSelectedShipment(shipment),
                waitingTimeBeforeRefreshingList: this.props.waitingTimeBeforeRefreshingList
            }}>
            <StyledTableResponsive>
                {hasErrors && (
                    <Snackbar
                        open={this.state.actionErrorNotification}
                        close
                        closeNotification={() => this.setState({actionErrorNotification: false})}
                        place={"bl"}
                        color={"danger"}
                        icon={NotificationsActive}
                        message={this.props.error}
                    />
                )}
                {!tableData ?
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={this.props.loading}
                        />
                    </div> :
                    <div className={classes.beatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={this.props.loading}
                        />
                    </div>
                }
                <StyledTable>
                    <ShowTableHeader />
                        <TableBody>
                            {(hasNotShippedLineItems) ? (
                                <>
                                    {tableData.map((item, index) => {
                                        return <LineItemRow lineItem={item} key={index}/>
                                    })}
                                    {(!!order.adjustments && order.adjustments.length > 0) && this.renderAdjustments(order.adjustments, classes)}
                                </>
                            ) : (!hasNotShippedLineItems && !hasShipments && !this.props.loading) && (
                                <TableRow>
                                    <TableCell colSpan={8} className={classes.tableCellNoResult}>
                                        <FormattedMessage id={"retailer.list.table.no_result"}/>
                                    </TableCell>
                                </TableRow>
                            )}
                            {this.props.shipments && (
                                <ShipmentList shipments={this.props.shipments} currentOrganization={this.props.currentOrganization}/>
                            )}
                        </TableBody>
                </StyledTable>

                {!hasAuthorization(this.props.authorizations, Authorizations.PICKING_READ_ONLY) &&
                    <div>
                        <Menu
                            onClose={() => this.setState({anchorEl: null,})}
                            anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)}
                        >
                            <MenuItem onClick={() => this.openLineItemPriceModal()}>
                                <FormattedMessage id={"picking.order.show.item.updatePrice"}/>
                            </MenuItem>
                        </Menu>
                        <CancellationReasonDialog />
                        <Dialog open={this.state.updatePriceModalOpening} onClose={() => this.handleLineItemPriceModalClose('price')} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title-change-price">
                                <FormattedMessage id={"picking.order.show.item.updatePrice"}/>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <FormattedMessage id={"picking.order.show.item.updatePrice.description"} values={{productName: this.state.itemBeingEdited?.name}} />
                                </DialogContentText>
                                <FormattedHTMLMessage id={"picking.order.show.item.newprice.currentstate"} values={{productUnitPrice: this.state.itemBeingEdited?.retailerPrice, currency: this.props.currentOrganization?.currency}}/>
                                <FormattedMessage id={"picking.order.show.item.newprice"}/>
                                <TextField
                                    variant="standard"
                                    id={"input-edit-price"}
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        max: this.state.itemBeingEdited?.retailerPrice,
                                        defaultValue: this.state.itemBeingEdited?.retailerPrice,
                                        type: 'number',
                                        onChange: (e) => this.changeLineItemPrice(this.state.itemBeingEdited, +e.target.value),
                                    }}
                                    defaultValue={this.state.itemBeingEdited?.retailerPrice}
                                    fullWidth />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleLineItemPriceModalClose('price')} color="primary">
                                    <FormattedMessage id={"picking.order.show.dialog.action.cancel"}/>
                                </Button>
                                <Button onClick={() => this.handleUpdateLinePrice(this.state.itemBeingEdited)} color="primary">
                                    <FormattedMessage id={"picking.order.show.dialog.action.confirm"}/>
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
            </StyledTableResponsive>
            </OrderContext.Provider>
        );
    }

    openLineItemPriceModal = () => {
        this.setState({ updatePriceModalOpening: true, anchorEl: null });
    }

    handleLineItemPriceModalClose = () => {
        this.setState({ updatePriceModalOpening: false, itemBeingEdited: null, anchorEl: null});
    }
}

const mapStateToProps = state => ({
    loading: state.lineItem.list.loading,
    error: state.lineItem.list.error,
    updateLoadingOrder: state.order.update.loading,
    updateLoadingLineItem: state.lineItem.update.loading,
    updateErrorLineItem: state.lineItem.update.error,
    member: state.authentication.member,
    organizations: state.authentication.organizations,
    currentOrganization: state.currentOrganization.retrieved ?? null,
    authorizations: state.authentication.authorizations
});

const mapDispatchToProps = dispatch => ({
    confirm: (item) => dispatch(confirm(item)),
    reserve: (item) => dispatch(reserve(item)),
    updatePrice: (item, price) => dispatch(updatePrice(item, price)),
    assign: (item, data) => dispatch(assign(item, data)),
    confirmReturningRunnerToRetailer: (item) => dispatch(confirmReturningRunnerToRetailer(item)),
    confirmReturn: (item) => dispatch(confirmReturn(item)),
    cancellationReason: (item, reason, unavailableDays) => dispatch(cancellationReason(item, reason, unavailableDays))
});

OrderTable.defaultProps = {
    organizationShippingMethods: [],
};

OrderTable.propTypes = {
    classes: PropTypes.object.isRequired,
    organizationShippingMethods: PropTypes.arrayOf(PropTypes.object)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(tableStyle)(injectIntl(OrderTable)));
