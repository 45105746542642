import React from 'react';
import PropTypes from 'prop-types';

import StatusBadge from "../../../../StatusBadge";

ProductStatusBadge.propTypes = {
    status: PropTypes.bool.isRequired
};

function ProductStatusBadge({status, ...props}) {
    return (
        <div style={{
            position: 'absolute',
            right: '12px',
            top: '12px'
        }}>
            <StatusBadge status={status} />
        </div>
    );
}

export default ProductStatusBadge;
