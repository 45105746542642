import {fetch, normalize,} from "../../utils/dataAccess";
import {success as deleteSuccess} from "./delete";

export function error(error) {
    return {type: "RETAILER_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "RETAILER_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "RETAILER_LIST_SUCCESS", retrieved};
}

export function countLoading(loading) {
    return {type: "RETAILER_LIST_COUNT_LOADING", loading};
}

export function countSuccess(retrieved) {
    return {type: "RETAILER_LIST_COUNT_SUCCESS", retrieved};
}

export function list(params = `order[pushActive]=desc&order[name]=asc&pagination=true&itemsPerPage=500&page=1`) {
    const url = "/retailers?" + params;

    return dispatch => {
        dispatch(loading(true));
        dispatch(error(""));

        fetch(url)
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function count(params) {
    return dispatch => {
        dispatch(countLoading(true));
        fetch("/picking/retailer/lineItem/count?" + params).then(response => {
            response.json().then(retrieved => {
                dispatch(countSuccess(retrieved));
                dispatch(countLoading(false));
            }).catch(() => {
                dispatch(countSuccess(false));
            });
        })
    }
}

export function reset() {
    return dispatch => {
        dispatch({type: "RETAILER_LIST_RESET"});
        dispatch(deleteSuccess(null));
    };
}
