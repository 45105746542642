import React from "react";
import {
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Button,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {FormattedMessage, injectIntl} from "react-intl";
import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";
import {withStyles} from "@mui/styles";

function ConfirmationDialog({open, handleClose, onClickConfirm, onClickCancel, title, content, confirmActionText, cancelActionText}) {

    function onClose(event, reason) {
        // Close dialog when clicking on the backdrop
        if (reason === 'backdropClick') {
            handleClose();
        }
    }

    return (
        <Dialog open={open} aria-labelledby={title} onClose={onClose}>
            <DialogTitle id={title}>
                <FormattedMessage id={title} />
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id={content} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClickCancel} color="primary">
                    <FormattedMessage id={cancelActionText} />
                </Button>
                <Button onClick={onClickConfirm} color="primary">
                    <FormattedMessage id={confirmActionText} />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(detailStyle)(injectIntl(ConfirmationDialog));
