import print from '../../../../../../api/shipment/print';
import {Check, Close, Print} from "@mui/icons-material";
import {useState} from "react";
import {makeStyles} from "@mui/styles";
import {CircularProgress, Tooltip} from "@mui/material";
import {FormattedMessage} from "react-intl";

const useStyle = makeStyles({
    printIcon: {
        paddingBottom: '3px',
        paddingLeft: '2px',
        cursor: 'pointer'
    },
    loadingIcon: {
        marginTop: '3px',
    }
});

export default function PrintButton({ shipmentDocument,  currentOrganization}) {
    const classes = useStyle();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handlePrint = async (shipmentDocument) => {
        setLoading(true);
        try {
            await print(shipmentDocument, currentOrganization);
            setSuccess(true);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <CircularProgress
                color="inherit"
                size={15}
                className={classes.loadingIcon}
            />
        );
    }

    if (error) {
        return (
            <Tooltip title={<FormattedMessage id={'picking.order.shipments.action.print.failure'} />}>
                <Close size={15}/>
            </Tooltip>
        );
    }

    if (success) {
        return (
            <Tooltip title={<FormattedMessage id={'picking.order.shipments.action.print.success'} />}>
                <Check size={15}/>
            </Tooltip>
        );
    }

    return (
        <Tooltip title={<FormattedMessage id={'picking.order.shipments.action.print'} />}>
            <Print
                onClick={() => handlePrint(shipmentDocument)}
                className={classes.printIcon}
            />
        </Tooltip>
    );
};