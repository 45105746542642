import React from 'react';
import PropTypes from 'prop-types';

import {createTheme, ThemeProvider} from "@mui/material/styles";

import Icon from "./components/Icon";
import Indicator from "./components/Indicator";
import Label from "./components/Label";
import BadgeContainer from "./components/BadgeContainer";

import {
    black500,
    blue50,
    blueColor,
    green50,
    green500,
    green600,
    grey300,
    greyishBlue100,
    greyishBlue700,
    orange50,
    orange500,
    red50,
    red500
} from "assets/jss/main";

import {BadgeContext} from "./BadgeContext";

Badge.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    color: PropTypes.oneOf([
        'default',
        'success',
        'online',
        'warning',
        'error',
        'archived',
        'draft',
        'outline'
    ]),
    size: PropTypes.oneOf([
        'default',
        'large'
    ]),
    rounded: PropTypes.bool,
    icon: PropTypes.bool,
    indicator: PropTypes.bool,
    backgroundColor: PropTypes.string,
    indicatorColor: PropTypes.string,
    iconColor: PropTypes.string,
    labelColor: PropTypes.string
};

// it would be a great idea to define this as global app theme
const theme = createTheme({
    palette: {
        default: {
            main: blueColor,
            light: blue50,
        },
        success: {
            main: green50,
            light: green600
        },
        online: {
            main: green500,
            light: green50
        },
        warning: {
            main: orange500,
            light: orange50,
        },
        error: {
            main: red500,
            light: red50,
        },
        archived: {
            main: greyishBlue700,
            light: greyishBlue100,
        },
        draft: {
            main: black500,
            light: grey300
        },
        outline: {
            main: greyishBlue700,
            light: '#FFFFFF'
        }
    },
    padding: {
        default: '2px 8px',
        large: '12px',
        noLabel: {
            default: '6px',
            large: '8px',
            hasIndicator: {
                default: '2px',
                large: '8px'
            },
        },
    },
    radius: {
        default: '4px',
        rounded: '48px'
    },
    border: {
        outline: {
            main: greyishBlue100,
        }
    }
});

function Badge({
    label = null,
    color = 'default',
    size = 'default',
    rounded = false,
    icon = false,
    indicator = false,
    backgroundColor = null,
    indicatorColor = null,
    iconColor = null,
    labelColor = null,
}) {
    return (
        <ThemeProvider theme={theme}>
            <BadgeContext.Provider value={{
                hasLabel: !!label,
                rounded,
                color,
                size,
                icon,
                indicator,
                backgroundColor,
                indicatorColor,
                iconColor,
                labelColor,
            }}>
                <BadgeContainer>
                    {indicator && <Indicator />}
                    {icon && <Icon />}
                    {null !== label && <Label label={label} />}
                </BadgeContainer>
            </BadgeContext.Provider>
        </ThemeProvider>
    );
}

export default Badge;
