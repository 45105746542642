import React from "react";

export const QuoteContext = React.createContext({
    quote: null,
    taxRates: null,
    isSubmitting: false,
    isProcessing: false,
    forceCustomerValidateQuote: () => {},
    validateQuote: () => {},
    validateOfflineQuote: () => {},
    refuseQuote: () => {},
    unlockQuote: () => {},
    openNewLineModal: () => {},
    openDeleteLineModal: () => {},
    refreshQuote: () => {},
    lineBeingEdited: null,
    setLineBeingEdited: () => {},
});
