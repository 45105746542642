import React, {useContext} from 'react'
import PropTypes from "prop-types";
import {FormattedDate, FormattedMessage} from "react-intl";
import {BeatLoader} from "react-spinners";

import makeStyles from "@mui/styles/makeStyles";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import HeaderActionButton from "./HeaderActionButton";
import GivenToCustomerButton from "./GivenToCustomerButton";
import TrackingNumbers from "./TrackingNumbers";

import {
    twelveGrey,
    lighterBlack,
    orangeColor,
    fourteenthGrey,
    primaryColor
} from "assets/jss/main";

import {OrderContext} from "contexts/OrderContext";

import {ShippingMethods} from "utils/ShippingMethods";

const defaultFontStyle = {
    fontSize: '20px',
    color: lighterBlack,
    fontWeight: 400
}
const useStyle = makeStyles({
    header: {
        background: twelveGrey,
        height: '88px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '30px',
        paddingRight: '30px',
    },
    titleAndDate: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        ...defaultFontStyle,
        fontWeight: 700,
    },
    errorContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '15px',
        marginRight: '15px'
    },
    error: {
        ...defaultFontStyle,
        fontSize: '14px',
        color: orangeColor,
        paddingLeft: '8px'
    },
    errorIcon: {
        width: '.7em'
    },
    date: {
        color: fourteenthGrey
    },
    pendingShipmentLabel: {
        ...defaultFontStyle,
        fontSize: '16px'
    },
    rightPart: {
        display: 'flex',
        alignItems: 'center'
    }
});

export default function ShipmentHeader({shipment, incrementalNumber, lineItems, currentOrganization}) {
    const classes = useStyle();

    const {
        setLineItemSelectedIds,
        toggleModalWaybillSelector,
        setSelectedShipment,
        waitingTimeBeforeRefreshingList
    } = useContext(OrderContext);

    const {
        shippedAt,
        shippingMethod,
        trackingNumber,
        returnTrackingNumber,
        error,
        shipmentLabel,
        returnShipmentLabel,
        createdAt
    } = shipment;

    const isClickAndCollect =
        lineItems[0].shippingMethod === ShippingMethods.CLICK_COLLECT
        || lineItems[0].shippingMethod ===  ShippingMethods.DRIVE
        || lineItems[0].shippingMethod === ShippingMethods.LOCKER;

    const showGivenToCustomerButton = shipment && shippedAt === null && isClickAndCollect

    const pendingShipmentLabel = !error && !trackingNumber && shippedAt === null;
    const showRegenerateButton =
        error
        && !trackingNumber
        && shippedAt === null
        && !waitingTimeBeforeRefreshingList
    ;

    const formattedShippedAtDate = (shipmentDate) => (
        <>
            <FormattedDate
                value={new Date(shipmentDate)}
                year={"numeric"}
                month={"numeric"}
                day={"numeric"}
            />&nbsp;-&nbsp;
            <FormattedDate
                value={new Date(shipmentDate)}
                hour={"numeric"}
                minute={"numeric"}
            />
        </>
    )

    return (
        <div className={classes.header}>
            <div className={classes.titleAndDate}>
                <span className={classes.title}>
                    <b>
                        <FormattedMessage id={`picking.order.shipments.header.expedition`} /> {incrementalNumber}
                        &nbsp;-&nbsp;
                        <FormattedMessage id={`picking.order.shippingMethod.${shippingMethod.shippingMethodShopName}`} />
                    </b>
                </span>
                <span className={classes.date}>{formattedShippedAtDate(shippedAt || createdAt)}</span>
            </div>
            {pendingShipmentLabel && (
                <span className={classes.pendingShipmentLabel}>
                    <FormattedMessage id={`picking.order.shipments.header.action.regenerate.pending`} />
                </span>
            )}
            {waitingTimeBeforeRefreshingList && (
                <BeatLoader
                    sizeUnit={"px"}
                    size={12}
                    color={primaryColor}
                    loading={true}
                />
            )}
            {(showRegenerateButton || error) && (
                <>
                    {error &&
                        <div className={classes.errorContainer}>
                            <ErrorOutlineIcon className={classes.errorIcon} htmlColor={orangeColor} />
                            <div>
                                <span className={classes.error}>{error}</span>
                            </div>
                        </div>
                    }
                    {showRegenerateButton &&
                        <HeaderActionButton
                            onClick={() => {
                                setSelectedShipment(shipment);
                                setLineItemSelectedIds(lineItems.map(li => li['@id']));
                                toggleModalWaybillSelector();
                            }}
                        >
                            <FormattedMessage id={`picking.order.shipments.header.action.regenerate`} />
                        </HeaderActionButton>
                    }
                </>
            )}
            {!pendingShipmentLabel && !waitingTimeBeforeRefreshingList && (
                <div className={classes.rightPart}>
                    <TrackingNumbers
                        trackingNumber={trackingNumber}
                        returnTrackingNumber={returnTrackingNumber}
                        shipment={shipment}
                        currentOrganization={currentOrganization}
                    />
                    {showGivenToCustomerButton && (
                       <GivenToCustomerButton shipment={shipment}>
                           <FormattedMessage id={`picking.order.shipments.header.action.givenToCustomer`} />
                       </GivenToCustomerButton>
                    )}
                </div>
            )}
        </div>
    )
}

ShipmentHeader.defaultProps = {
    incrementalNumber: 0,
    shipment: {},
};

ShipmentHeader.propTypes = {
    incrementalNumber: PropTypes.number.isRequired,
    shipment: PropTypes.shape({
        shippedAt: PropTypes.string,
        shippingMethod:  PropTypes.shape({
            shippingMethodShopName: PropTypes.string
        }),
        trackingNumber: PropTypes.string,
        returnTrackingNumber: PropTypes.string,
        shipmentLabel: PropTypes.string,
        returnShipmentLabel: PropTypes.string,
        errorMessage: PropTypes.string,
        createdAt: PropTypes.string
    })
};
