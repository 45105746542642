import {fetch, normalize} from "../../utils/dataAccess";

export function error(error) {
    return {type: "RETAILER_SHOW_ERROR", error};
}

export function loading(loading) {
    return {type: "RETAILER_SHOW_LOADING", loading};
}

export function success(retrieved) {
    return {type: "RETAILER_SHOW_SUCCESS", retrieved};
}

export function retrieve(id) {

    // https://media3.giphy.com/media/l2JhrYYxAD6N5gble/giphy.gif
    if (id.includes('%2Fretailers%2F')) {
        id = decodeURIComponent(id);
    }

    // Old routes contains IRI encoded directly
    // while new routes only contains the id
    // this condition allows us to support old and new routes
    if (!id.includes('/retailers/')) {
        id = '/retailers/' + id;
    }

    return dispatch => {
        dispatch(loading(true));

        return fetch(id)
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "RETAILER_SHOW_RESET"});
        dispatch(error(null));
        dispatch(loading(false));
    };
}
