import React from 'react'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";

import makeStyles from "@mui/styles/makeStyles";

import DownloadShipmentFile from "./DownloadShipmentFile";

import {lighterBlack} from "assets/jss/main";
import PrintButton from "./PrintButton";

const useStyle = makeStyles({
    trackingNumbers: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
    },
    title: {
        fontSize: '16px',
        color: lighterBlack,
        fontWeight: 400,
        display: 'flex',
        letterSpacing: '0.192px',
    },
    number: {
        color: lighterBlack,
        fontWeight: 'bold',
        '&:visited': {
            color: lighterBlack,
        }
    },
    numberUnderline: {
        textDecoration: 'underline',
    },
    emptySpace: {
        width: '24px',
        height: '24px'
    }
});

export default function TrackingNumbers({trackingNumber, returnTrackingNumber, shipment, currentOrganization}) {
    const classes = useStyle();

    const shipmentLabels = shipment.documents ? Object.values(shipment.documents).filter(item => item['type'] === "waybill") : null;
    const shipmentLabel = shipmentLabels && shipmentLabels.length ? shipmentLabels[0]['url'] : null;

    const returnShipmentLabels = shipment.documents ? Object.values(shipment.documents).filter(item => item['type'] === "return-waybill") : null;
    const returnShipmentLabel = returnShipmentLabels && returnShipmentLabels.length ? returnShipmentLabels[0]['url'] : null;

    const returnForms = shipment.documents ? Object.values(shipment.documents).filter(item => item['type'] === "return-form") : null;
    const returnForm = returnForms && returnForms.length ? returnForms[0]['url'] : null;

    return (
        <div className={classes.trackingNumbers}>
            {trackingNumber && (
                <span className={classes.title}>
                    <FormattedMessage id={'picking.order.shipments.header.trackingNumber.send'} />&nbsp;:&nbsp;
                    {shipmentLabel ? (
                        <>
                            <a href={shipmentLabel} className={classNames(classes.number, classes.numberUnderline)}>
                                {trackingNumber}
                            </a>
                            <DownloadShipmentFile shipmentLabelLink={shipmentLabel} />
                            {currentOrganization.printNodeKey &&
                                <PrintButton shipmentDocument={shipmentLabels[0]} currentOrganization={currentOrganization}></PrintButton>
                            }
                        </>
                    ) : (
                        <>
                            <span className={classes.number}>{trackingNumber}</span>
                            <div className={classes.emptySpace}></div>
                        </>
                    )}
                </span>
            )}
            {returnTrackingNumber && (
                <span className={classes.title}>
                    <FormattedMessage id={'picking.order.shipments.header.trackingNumber.return'} />&nbsp;:&nbsp;
                    {returnShipmentLabel ? (
                        <>
                            <a href={returnShipmentLabel} className={classNames(classes.number, classes.numberUnderline)}>
                                {returnTrackingNumber}
                            </a>
                            <DownloadShipmentFile shipmentLabelLink={returnShipmentLabel} />
                            {currentOrganization.printNodeKey &&
                                <PrintButton shipmentDocument={returnShipmentLabels[0]} currentOrganization={currentOrganization}></PrintButton>
                            }
                        </>
                    ) : (
                        <>
                            <span className={classes.number}>{returnTrackingNumber}</span>
                            <div className={classes.emptySpace}></div>
                        </>
                    )}
                </span>
            )}
            {returnForm && (
                <span className={classes.title}>
                    <a href={returnForm} className={classNames(classes.number, classes.numberUnderline)}>
                         <FormattedMessage id={'picking.order.shipments.header.trackingNumber.return-form'} />
                    </a>
                    <DownloadShipmentFile shipmentLabelLink={returnForm} />
                    {currentOrganization.printNodeKey &&
                        <PrintButton shipmentDocument={returnForms[0]} currentOrganization={currentOrganization}></PrintButton>
                    }
                </span>
            )}
        </div>
    )
}

TrackingNumbers.defaultProps = {
    trackingNumber: '',
    returnTrackingNumber: '',
    shipmentLabel: '',
    returnShipmentLabel: ''
}

TrackingNumbers.propTypes = {
    trackingNumber: PropTypes.string,
    returnTrackingNumber: PropTypes.string,
    shipmentLabel: PropTypes.string,
    returnShipmentLabel: PropTypes.string
}
