import React from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import CustomSwitch from "components/switch/CustomSwitch";

function StatusField({}) {
    const {values, handleChange} = useFormikContext();

    return (
        <>
            <CustomSwitch
                name='status'
                checked={values.status}
                onClick={handleChange}
            />
            <FormattedMessage id='product.show.variation.form.field.status' />
        </>
    );
}

export default injectIntl(StatusField);
