import React from 'react';
import PropTypes from 'prop-types';

import {ImageSharp} from "@mui/icons-material";

import ProductStatusBadge from "./components/ProductStatusBadge";

ProductImage.propTypes = {
    image: PropTypes.string,
    status: PropTypes.bool,
};

function ProductImage({image, status}) {
    return (
        <div style={{ height: '336px', position: 'relative' }}>
            <ProductStatusBadge status={status} />
            {image ? (
                <img
                    src={image}
                    alt={'product image'}
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        borderRadius: '8px'
                    }}
                />
            ) : (
                <ImageSharp color={"disabled"} sx={{width: '100%', height: '100%'}}/>
            )}
        </div>
    );
}

export default ProductImage;
