import React, {Component} from "react";
import * as moment from "moment";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {PropagateLoader, BeatLoader} from 'react-spinners';

// @mui/material components
import {withStyles} from "@mui/styles";
import {Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Popover, Typography} from "@mui/material";

// @mui/icons-material
import {
    Edit,
    Delete,
    ExitToApp,
    AttachMoney,
    MoneyOff,
    ReportProblemOutlined,
    MoreVert,
    Power
} from "@mui/icons-material";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

// styles
import tableStyle from "assets/jss/views/retailer/tableStyle";
import {primaryColor, grayColor, successColor, dangerColor, orangeColor, orangeReturn} from "assets/jss/main";
import inboxLogo from "../../../assets/img/inbox.gif";

// helpers
import {capitalize} from "utils/capitalize";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";

class RetailerTable extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        deleteLoading: PropTypes.bool.isRequired,
        deleted: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            anchorElAll: null,
        };
    }

    displayValidProducts = (count) => {
        if (count > 0) {
            return (
                <div>{count}</div>
            )
        }

        return (
            <div style={{color: dangerColor}}>0</div>
        )

    }

    displayMissingMappings = (count) => {
        if (count > 0)
            return <ReportProblemOutlined style={{color: dangerColor}}/>;
    }

    displayCountDaysSinceLastUpdate = (days, lastUpdate, retailer, classes)  => {
        const isRetailerActive = retailer['pushActive'] && (retailer['pushWithoutStripe'] || (retailer['paymentAccount'] && retailer['paymentAccount']['enabled']));
        if (days > 0 && isRetailerActive) {
            const computedLastUpdate = moment(lastUpdate).format('DD/MM/YYYY');

            return (
                <div>
                    <div>
                        <strong>
                            <FormattedMessage
                            id={"retailer.list.table.body.days"}
                            values={{nb: days}}
                            />
                        </strong>
                    </div>
                    <div className={classes.tableCellComplementaryContent}>
                        <FormattedMessage
                            id={"retailer.list.table.body.days.complementary"}
                            values={{date: computedLastUpdate}}
                        />
                    </div>
                </div>
            );
        }
    }

    displayHeaders = (tableHead, classes) => {
        // we need to change the order of the missing mapping in header (and to remove its title) when we display this table on mobile
        return (
            <TableRow>
                <TableCell
                    className={classes.tableCellCenter + " " +classes.mobileOnly + " " + classes.tableHeadCell}
                    key='mobile-missing-mapping'
                >
                </TableCell>
                {tableHead.map((prop, key) => {
                    return (
                        <TableCell
                            className={(prop.props.id === 'retailer.list.table.header.warning.mappings')? classes.tableCellCenter + " " + classes.tableHeadCell  + " " +classes.desktopOnly : classes.tableCellCenter + " " + classes.tableHeadCell }
                            key={key}
                        >
                            {prop}
                        </TableCell>
                    );
                })}
                <TableCell/>
            </TableRow>
        )
    }

    displayMobileAction(selected, classes, messageId) {
        if (selected != null && 'undefined' !== typeof(selected)) {
            let actionLink;

            if (messageId ===  "retailer.list.table.edit") {
                actionLink = `edit/${encodeURIComponent(selected["@id"])}&${encodeURIComponent(this.props.match.params.params)}`;
            } else {
                actionLink = `show/${encodeURIComponent(selected["@id"])}`;
            }

            return (
                <Typography
                    className={classes.typography}
                >
                    <Link to={actionLink} className={classes.mobileActionLink}>
                        <FormattedMessage id={messageId}/>
                    </Link>
                </Typography>
            )
        }
    }

    handleClick(event, item) {
        event.stopPropagation();

        this.setState({anchorEl: event.currentTarget});
        this.setState({selected: item})
    }

    handleClose = (event) => {
        event.persist();
        event.stopPropagation();

        this.setState({anchorEl: null})
        this.setState({selected: null})
    };

    render() {
        const {classes, tableHead, tableData, tableHeaderColor} = this.props;
        const {anchorEl, selected} = this.state;

        return (
            <div className={classes.tableResponsive}>
                {!tableData ?
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={this.props.loading || this.props.deleteLoading}
                        />
                    </div> :
                    <div className={classes.beatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={this.props.loading || this.props.deleteLoading}
                        />
                    </div>
                }
                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            {this.displayHeaders(tableHead, classes)}
                        </TableHead>
                    ) : null}

                    {(tableData && tableData.length) ?
                    <TableBody>
                        {tableData.map((item, key) => {
                            return (
                                <TableRow key={key} hover>
                                    <TableCell className={classes.mobileOnly + " " + classes.tableCellCenter}>
                                        <Link to={`${encodeURIComponent(item.id)}/attributes-mapping`} className={classes.link}>
                                            {this.displayMissingMappings(item['missingMappings'])}
                                        </Link>
                                    </TableCell>
                                    <TableCell className={classes.tableCellCenter}>
                                        <Link to={`show/${encodeURIComponent(item["@id"])}`} className={classes.link}>
                                            <div>{capitalize(item["name"])}</div>
                                        </Link>
                                        { item.paymentAccount && item.paymentAccount.accountId &&
                                            <Link to={`show/${encodeURIComponent(item["@id"])}`} className={classes.linkStripe}>
                                                <div>{item.paymentAccount.accountId}</div>
                                            </Link>
                                        }
                                    </TableCell>
                                    <TableCell className={classes.desktopOnly + " " + classes.tableCellCenter}>
                                        <Link to={`${encodeURIComponent(item.id)}/attributes-mapping`} className={classes.link}>
                                            {this.displayMissingMappings(item['missingMappings'])}
                                        </Link>
                                    </TableCell>
                                    <TableCell className={classes.tableCellCenter}>
                                        <div style={{color: dangerColor }}>{this.displayCountDaysSinceLastUpdate(item['daysSinceLastUpdate'], item['lastFeedUpdate'], item, classes)}</div>
                                    </TableCell>
                                    <TableCell className={classes.tableCellCenter}>
                                        {this.displayValidProducts(item['validProductsCount'])}
                                    </TableCell>

                                    <TableCell className={classes.desktopOnly + " " +classes.tableCellXL}>
                                    { (this.props.stripeLoading) ?
                                        <GridContainer>
                                            <div className={classes.propagateLoader}>
                                            <PropagateLoader
                                            sizeUnit={"px"}
                                            size={16}
                                            color={primaryColor}
                                            loading={this.props.stripeLoading}
                                            />
                                            </div>
                                            </GridContainer>
                                        :
                                        <GridContainer>
                                            <GridItem xs={1}>
                                                {item['importingFeeds'] &&(
                                                    <Tooltip title={<FormattedMessage id={"feed.list.table.importInProgress"} />}>
                                                        <img
                                                            src={inboxLogo}
                                                            alt={this.props.intl.formatMessage({id: "member.list.search.placeholder"})}
                                                            width={25}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </GridItem>
                                            <GridItem xs={1}>
                                                {hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT) &&
                                                <span onClick={() => {this.props.del(item);}}>
                                                    <Tooltip
                                                        title={<FormattedMessage
                                                            id={"retailer.list.table.tooltip.delete"}/>}>
                                                        <Delete style={{color: primaryColor, cursor: "pointer"}}/>
                                                    </Tooltip>
                                                </span>
                                                }
                                            </GridItem>
                                            <GridItem xs={1}>
                                                {hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT) &&
                                                <Link to={`edit/${encodeURIComponent(item["@id"])}&${encodeURIComponent(this.props.match.params.params)}`}>
                                                    <Tooltip
                                                        title={<FormattedMessage
                                                            id={"retailer.list.table.tooltip.edit"}/>}>
                                                        <Edit style={{color: primaryColor, cursor: "pointer"}}/>
                                                    </Tooltip>
                                                </Link>
                                                }
                                            </GridItem>
                                            <GridItem xs={1}>
                                                {hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT) &&
                                                    <div>
                                                        { (item['paymentAccount'] && item['paymentAccount']['enabled'] === true) ?
                                                            <Tooltip title={<FormattedMessage id={"retailer.list.table.tooltip.stripe.enable"}/>}>
                                                                <AttachMoney style={{color: successColor}}/>
                                                            </Tooltip>
                                                            :
                                                            <div onClick={() => {
                                                                if (window.confirm(this.props.intl.formatMessage({id: "retailer.list.table.action.confirm"}))) {
                                                                    this.props.createStripe(item);
                                                                    this.setState({loadingStripeId: item['id']});
                                                                }
                                                            }
                                                            }>
                                                                <Tooltip title={<FormattedMessage id={"retailer.list.table.tooltip.stripe.disable"}/>}>
                                                                    <MoneyOff style={{color: grayColor, cursor: "pointer"}}/>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </GridItem>
                                            <GridItem xs={1}>
                                                {item['pushActive'] ?
                                                    <>
                                                        { (item['paymentAccount'] && item['paymentAccount']['enabled']) ?
                                                            <Tooltip title={<FormattedMessage id={"retailer.list.table.tooltip.pushActive"} />}>
                                                                <Power style={{color: successColor}} />
                                                            </Tooltip>
                                                            : item['pushWithoutStripe'] ?
                                                            <Tooltip title={<FormattedMessage id={"retailer.list.table.tooltip.pushWithoutStripe"} />}>
                                                                <Power style={{color: orangeReturn}} />
                                                            </Tooltip>
                                                                :
                                                                <Tooltip title={<FormattedMessage id={"retailer.list.table.tooltip.pushNotActive"} />}>
                                                                    <Power style={{color: grayColor}} />
                                                                </Tooltip>
                                                        }
                                                    </>
                                                    :
                                                    <Tooltip title={<FormattedMessage id={"retailer.list.table.tooltip.pushNotActive"} />}>
                                                        <Power style={{color: grayColor}} />
                                                    </Tooltip>
                                                }
                                            </GridItem>
                                            <GridItem xs={1}>
                                                <Link to={`show/${encodeURIComponent(item["@id"])}`}>
                                                    <Tooltip
                                                        title={<FormattedMessage
                                                            id={"retailer.list.table.tooltip.dashboard"}/>}>
                                                        <ExitToApp style={{color: primaryColor, cursor: "pointer"}}/>
                                                    </Tooltip>
                                                </Link>
                                            </GridItem>
                                        </GridContainer>
                                        }
                                    </TableCell>

                                    <TableCell className={classes.mobileOnly + " " +classes.actionsButtonContainer}>
                                        <MoreVert className={classes.icon} onClick={(e) => this.handleClick(e, item)}/>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody> :
                    <TableBody>
                        {(tableData) &&
                        <TableRow>
                            <TableCell colSpan={4} className={classes.tableCellNoResult}><FormattedMessage
                                id={"retailer.list.table.no_result"}/></TableCell>
                        </TableRow>
                        }
                    </TableBody>
                    }
                </Table>

                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {/*delete retailer*/}
                    {hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT) &&
                    <Typography
                        className={classes.typography}
                        onClick={() => {this.props.del(selected);}}
                    >
                        <FormattedMessage id={"retailer.list.table.row.delete"} />
                    </Typography>
                    }

                    {/*edit retailer on mobile*/}
                    {hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT) &&
                    this.displayMobileAction(selected, classes, "retailer.list.table.edit")
                    }

                    {/*access to dashboard on mobile*/}
                    {this.displayMobileAction(selected, classes, "retailer.list.table.dashboard")}
                </Popover>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    deleteLoading: state.retailer.del.loading,
    deleted: state.retailer.del.deleted,
    loading: state.retailer.list.loading,
    authorizations: state.authentication.authorizations,
    stripeLoading: state.retailer.create.loading
});

RetailerTable.defaultProps = {
    tableHeaderColor: "gray"
};

RetailerTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.object),
    tableData: PropTypes.array
};

export default connect(
    mapStateToProps,
    null,
)(withStyles(tableStyle)(injectIntl(RetailerTable)));
