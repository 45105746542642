import React, {useContext, useEffect, useRef, useState} from "react";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";

import {FormattedMessage, injectIntl} from "react-intl";
import {BeatLoader} from "react-spinners";
import {Formik} from "formik";
import debounce from "lodash.debounce";

import yup from "../../../../assets/translations/validation";
import {QuoteContext} from "../../../../contexts/quoteContext";

import {getTranslation} from "../../../../domain/helpers/translations";
import FormikInput from "../../../../components/input/formikInput";

import createLine from "../../../../api/quote/line/create";
import {listByProductSku} from "../../../../api/product/variations/list";
import updateLineItem from "../../../../api/quote/lineItem/update";

import {MAX_QUANTITY_PER_LINE} from "../../enums/constants";
import {getQuantityPromotionByQuantity} from "./grid/gridLine";
import {styled} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import SaveButton from "../../../../components/button/SaveButton";
import CancelButtonStyled from "../../../../components/button/CancelButtonStyled";
import {
    borderGrayColor,
    dangerColor,
    fifteenthGrey,
    grayColor,
    greyColorHover,
    infoColor,
} from "assets/jss/main";

const schema = yup.object({
    productSku: yup.string().required("L'identifiant du produit est obligatoire"),
    variant: yup.string().required("Vous devez sélectionner une variation"),
    quantity: yup.number().required("La taille est obligatoire").positive().max(MAX_QUANTITY_PER_LINE),
});

let retrieveVariations = () => {};
let lastSku = null;
const retrieveVariationsDebouncer = debounce(() => retrieveVariations(), 500);

const useStyle = makeStyles({
    fieldError: {
        color: dangerColor,
        fontSize: '12px'
    },
});

const StyledDialogTitle = styled(DialogTitle)({
    margin: '0px 0px 32px 0px',
    padding: 0,
    color: '#333',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '0.15px',
});

const StyledDialogContent = styled(DialogContent)({
    margin: '0px 0px 48px 0px',
    padding: 0,
    width: '100%',
    '& label': {
        margin: '24px 0px 4px 0px',
        padding: 0,
        color: fifteenthGrey,
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& div:first-child label': {
        marginTop: 0,
    },
    '& input, & select': {
        display: 'flex',
        padding: '16px',
        alignItems: 'center',
        gap: '10px',
        alignSelf: 'stretch',
        borderRadius: '8px !important',
        border: `1px solid ${borderGrayColor}`,
        background: '#FFF',
        color: grayColor,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        '&:hover': {
            border: `1px solid ${infoColor}`,
        },
        '&:focus': {
            color: fifteenthGrey,
            border: `1px solid ${infoColor}`,
            backgroundColor: greyColorHover,
        },
        '&:focus-within': {
            outline: 'none',
        },
    },
});

const StyledDialogActions = styled(DialogActions)({
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    gap: '12px',
    padding: 0,
    '& > *': {
        margin: '0 !important',
    },
});

const QuoteAddProductModal = ({ onCancel, intl }) => {
    const { quote, refreshQuote } = useContext(QuoteContext);
    const [creating, setCreatingProduct] = useState(false);
    const [fetchingVariations, setFetchingVariations] = useState(false);
    const [variations, setVariations] = useState(null);
    const [productSku, setProductSku] = useState('');
    const classes = useStyle();

    const onCancelModal = (resetForm) => {
        setVariations(null);
        setFetchingVariations(false);
        resetForm();
        onCancel();
    };
    const onAddProductFinish = (formik) => {
        setCreatingProduct(false);
        onCancelModal(formik.resetForm);
        refreshQuote();
    };

    const addProduct = (values, formik) => {
        const relatedVariation = variations.find(variation => variation.id === values.variant);
        setCreatingProduct(true);

        let existingLine = null;
        for (const line of quote.lines) {
            if (line.variation.sku === relatedVariation.sku) {
                existingLine = line;
                break;
            }
        }

        if (existingLine) {
            createLineItemUnits(existingLine, values.quantity, formik)
            return;
        }

        createLine(quote['@id'], Math.round(relatedVariation.price * 100), relatedVariation['@id']).then(line => createLineItemUnits(line, values.quantity, formik)).catch(() => onAddProductFinish(formik));
    };

    const createLineItemUnits = (line, quantity, formik) => {
        let promises = [];
        let units = line.units ?? [];
        for (let i = 0; i < quantity; i++) {
            /* Unit body is empty on push */
            units.push({});
        }

        const quantityPromotion = getQuantityPromotionByQuantity(line, quantity);
        if(quantityPromotion) {
            line.discount = (1 - (quantityPromotion.discountedPrice * 100 / line.unitPrice)) * 100;
        }

        Promise.all(promises).then(() => {
            updateLineItem(line.id, line.unitPrice, line.taxRate.id, line.discount, units).then(() => onAddProductFinish(formik)).catch(() => onAddProductFinish(formik));
        });
    }

    const renderVariationsSelect = (setFormikField, formikValues) => {
        return <FormikInput
            labelTranslationId={'picking.quote.detail.addProductModal.variant_field.label'}
            inputProps={
                {
                    name: 'variant',
                    as: 'select',
                    fieldError: classes.fieldError,
                    children: getOptionsForVariations(),
                    validate: (value) => {
                        const error = intl.formatMessage({id: 'picking.quote.detail.addProductModal.variant_field.error'});
                        return value === 'default' ? error : null;
                    },
                }
            }
        />;
    }

    const getOptionsForVariations = () => {
        if (!variations || !Array.isArray(variations)) {
            return null;
        }

        const emptyOptionsList = [
            <option value="default" key="default">
                {intl.formatMessage({id:'picking.quote.detail.addProductModal.variant_field.select_label'})}
            </option>
        ];

        const sortedVariations = variations.map(variation => {
            const sortedOptions = [...variation.retailerOptionValues].sort((optA, optB) => {
                const positionA = optA.option?.position ?? Infinity;
                const positionB = optB.option?.position ?? Infinity;

                return positionA - positionB;
            });

            return {
                ...variation,
                retailerOptionValues: sortedOptions
            };
        });

        const generateCombinations = (variations) => {
            const combinations = [];

            variations.forEach(variation => {
                const optionNames = variation.retailerOptionValues.map(option => {

                    return getTranslation(option).name;
                });

                combinations.push({
                    combination: optionNames.join(' - '),
                    optionValuePosition: variation.retailerOptionValues.map(opt => {
                        if (opt.optionValue?.position) {
                            return opt.optionValue.position;
                        }
                        return getTranslation(opt).name;
                    }),
                    variationId: variation.id
                });
            });

            return combinations;
        };

        const unsortedCombinations = generateCombinations(sortedVariations);

        const finalCombinations = unsortedCombinations.sort((a, b) => {
            for (let i = 0; i < Math.max(a.optionValuePosition.length, b.optionValuePosition.length); i++) {
                const posA = a.optionValuePosition[i];
                const posB = b.optionValuePosition[i];

                if (typeof posA === 'number' && typeof posB === 'number') {
                    if (posA !== posB) {
                        return posA - posB;
                    }
                } else {
                    const nameA = typeof posA === 'string' ? posA : '';
                    const nameB = typeof posB === 'string' ? posB : '';
                    const nameComparison = nameA.localeCompare(nameB);

                    if (nameComparison !== 0) {
                        return nameComparison;
                    }
                }
            }

            return 0;
        });

        const optionElements = finalCombinations.map((combo, index) => (
            <option key={combo.variationId} value={combo.variationId}>
                {combo.combination}
            </option>
        ));

        return emptyOptionsList.concat(optionElements);
    }

    const didMount = useRef(false);
    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }
        retrieveVariationsDebouncer();
    }, [productSku]);

    retrieveVariations = () => {
        if (!productSku) {
            return;
        }

        lastSku = productSku;

        setFetchingVariations(true);
        const queryParameter = new URLSearchParams();
        queryParameter.set('exists[eshopId]', 'true');
        listByProductSku(productSku, queryParameter).then(variations => {
            if(lastSku === productSku) {
                setVariations(variations['hydra:member'].filter(variation => variation.validated && variation.status));
                setFetchingVariations(false);
            }
        });
    };

    return (
        <Formik
            initialValues={{
                productSku: '',
                variant: '',
                quantity: 1,
            }}
            validationSchema={schema}
            validateOnChange
            onSubmit={addProduct}
        >{({
               submitForm,
               resetForm,
               handleChange,
               setFieldValue,
               values,
           }) => (
            <React.Fragment>
                <StyledDialogTitle>
                    <FormattedMessage id={"picking.quote.detail.addProductModal.title"}/>
                </StyledDialogTitle>
                <StyledDialogContent>
                    {(Array.isArray(variations) && variations.length === 0 && !fetchingVariations) &&
                        <FormattedMessage id={'picking.quote.detail.addProductModal.no_variation_for_sku'} values={{productSku: lastSku}}/>
                    }

                    {fetchingVariations && <FormattedMessage id={'picking.quote.detail.addProductModal.variations_loading.label'}/>}

                    <FormikInput
                        labelTranslationId={'picking.quote.detail.addProductModal.product_sku_field.label'}
                        inputProps={
                            {
                                name: 'productSku',
                                type: 'text',
                                fieldError: classes.fieldError,
                                placeholder: intl.formatMessage({id: 'picking.quote.detail.addProductModal.product_sku_field.placeholder'}),
                                onChange: (e) => {
                                    setProductSku(e.target.value);
                                    handleChange(e);
                                    if (e.target.value.trim() === '') {
                                        setVariations(null);
                                    }
                                }
                            }
                        }
                    />
                    {Array.isArray(variations) && variations.length > 0 && (<>
                        {renderVariationsSelect(setFieldValue, values)}

                        <FormikInput
                            labelTranslationId={'picking.quote.detail.addProductModal.quantity_field.label'}
                            inputProps={
                                {
                                    name: 'quantity',
                                    type: 'number',
                                    fieldError: classes.fieldError,
                                    min: '1',
                                }
                            }
                        />
                    </>)}
                </StyledDialogContent>
                <StyledDialogActions>
                    <CancelButtonStyled
                        onClick={() => onCancelModal(resetForm)}
                    >
                        <FormattedMessage id={"picking.quote.detail.addProductModal.cancel"}/>
                    </CancelButtonStyled>
                    <SaveButton
                        onClick={submitForm}
                        disabled={values.productSku === '' || values.variant === ''}
                    >
                        <FormattedMessage id={"picking.quote.detail.addProductModal.confirm"}/>
                        {creating && <BeatLoader
                            sizeUnit={"px"}
                            size={4}
                            color={"#FFF"}
                            css={{ marginLeft: '10px'}}
                        />}
                    </SaveButton>
                </StyledDialogActions>
            </React.Fragment>
        )}
        </Formik>
    );
}

export default injectIntl(QuoteAddProductModal);
