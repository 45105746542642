import React, {useContext, useState} from "react";
import {ArrowDownward, CheckRounded, CloseRounded, LockOpen} from "@mui/icons-material";
import {
    createTheme,
    Fab,
    IconButton,
    Tooltip
} from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import {FormattedMessage, injectIntl} from "react-intl";

import {QuoteContext} from "../../../../../contexts/quoteContext";
import {dangerColor, sidebarColor, successColor} from "../../../../../assets/jss/main";
import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";
import {REVIEW_IN_PROGRESS, WAITING_CUSTOMER, WAITING_REVIEW} from "../../../enums/state";
import {downloadQuotePdf} from "../../../../../api/quote";
import {withStyles} from "@mui/styles";
import ConfirmationDialog from "../dialog/confirmationDialog";
import HtmlConfirmationDialog from "../dialog/htmlConfirmationDialog";

const QuoteDetailHeaderActions = ({ classes, intl }) => {
    const theme = createTheme({
        palette: {
            default: {main: sidebarColor},
            primary: {main: successColor},
            secondary: {main: dangerColor},
        },
    });

    const [dialogType, setDialogType] = useState('validate');
    const [dialogOpened, setDialogOpened] = useState(false);

    // Offline validation confirmation dialog
    const [offlineDialogOpened, setOfflineDialogOpened] = useState(false);
    // Online validation confirmation dialog
    const [onlineDialogOpened, setOnlineDialogOpened] = useState(false);

    const {quote, isProcessing, forceCustomerValidateQuote, validateQuote, validateOfflineQuote, refuseQuote, unlockQuote, isSubmitting} = useContext(QuoteContext);
    const [downloadingPdf, setDownloadingPdf] = useState(false);
    if (!quote) {
        return null;
    }

    const downloadPdf = async () => {
        if (downloadingPdf) {
            return;
        }

        setDownloadingPdf(true);
        await downloadQuotePdf(quote.id, quote.number);
        setDownloadingPdf(false);
    }

    const openDialogType = function (type){
        setDialogType(type);
        setDialogOpened(true);
    }

    const handleAction = (action) => {
        if('forceCustomerValidation' === action) {
            forceCustomerValidateQuote();
            setDialogOpened(false);
        }
        if('validate' === action) {
            validateQuote();
            setOnlineDialogOpened(false);
        }
        if('offlineValidate' === action) {
            validateOfflineQuote();
            setOfflineDialogOpened(false);
        }
        if('refuse' === action) {
            refuseQuote();
            setDialogOpened(false);
        }
        if('edit' === action) {
            unlockQuote();
            setDialogOpened(false);
        }
    }

    const canAccept = [
        WAITING_REVIEW,
        REVIEW_IN_PROGRESS,
    ].indexOf(quote.state) !== -1;

    const actionButtonsDisabled = !canAccept || isProcessing || isSubmitting;

    function renderConfirmationDialogs() {
        // For this case we want double confirmation
        if (dialogType === 'validate') {
            return (
                <>
                    <ConfirmationDialog
                        open={dialogOpened}
                        handleClose={() => {
                            setDialogOpened(false);
                        }}
                        // Cancel action is used as "offline validation"
                        onClickCancel={() => {
                            setOfflineDialogOpened(true);
                            setDialogOpened(false);
                        }}
                        // Confirm action is used as "online validation"
                        onClickConfirm={() => {
                            setOnlineDialogOpened(true);
                            setDialogOpened(false);
                        }}
                        title={'picking.quote.detail.dialog.title.' + dialogType}
                        content={'picking.quote.detail.dialog.content.' + dialogType}
                        confirmActionText={'picking.quote.detail.dialog.button.online_validate'}
                        cancelActionText={'picking.quote.detail.dialog.button.offline_validate'}
                    />
                    <HtmlConfirmationDialog
                        open={offlineDialogOpened}
                        handleClose={() => {
                            setOfflineDialogOpened(false);
                        }}
                        onClickCancel={() => {
                            setOfflineDialogOpened(false);
                            setDialogOpened(true);
                        }}
                        onClickConfirm={() => {
                            handleAction('offlineValidate');
                        }}
                        title={'picking.quote.detail.dialog.title.offlineValidate'}
                        content={'picking.quote.detail.dialog.content.offlineValidate'}
                        confirmActionText={'picking.quote.detail.dialog.button.confirm'}
                        cancelActionText={'picking.quote.detail.dialog.button.cancel'}
                    />
                    <ConfirmationDialog
                        open={onlineDialogOpened}
                        handleClose={() => {
                            setOnlineDialogOpened(false);
                        }}
                        onClickCancel={() => {
                            setOnlineDialogOpened(false);
                            setDialogOpened(true);
                        }}
                        onClickConfirm={() => {
                            handleAction(dialogType);
                        }}
                        title={'picking.quote.detail.dialog.title.onlineValidate'}
                        content={'picking.quote.detail.dialog.content.onlineValidate'}
                        confirmActionText={'picking.quote.detail.dialog.button.confirm'}
                        cancelActionText={'picking.quote.detail.dialog.button.cancel'}
                    />
                </>
            );
        }

        let DialogComponent = (dialogType === 'forceCustomerValidation') ? HtmlConfirmationDialog : ConfirmationDialog;
        return (
            <DialogComponent
                open={dialogOpened}
                handleClose={() => {
                    setDialogOpened(false)
                }}
                onClickCancel={() => {
                    setDialogOpened(false)
                }}
                onClickConfirm={() => {
                    handleAction(dialogType)
                }}
                title={'picking.quote.detail.dialog.title.' + dialogType}
                content={'picking.quote.detail.dialog.content.' + dialogType}
                confirmActionText={'picking.quote.detail.dialog.button.confirm'}
                cancelActionText={'picking.quote.detail.dialog.button.cancel'}
            />
        );
    }

    return (
        <div>
            <div>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <span className={classes.orderActions}>
                            <span>
                                {intl.formatMessage({id: "picking.quote.detail.header.actions"})} :
                            </span>
                            {
                                quote.state !== WAITING_CUSTOMER && (
                                    <>
                                        <Fab
                                            disabled={actionButtonsDisabled}
                                            color="primary"
                                            size={'small'}
                                            className={classes.validationButton}
                                            onClick= {() => {openDialogType('validate')}}
                                        >
                                            <Tooltip title={<FormattedMessage id={'picking.quote.detail.header.actions.accept'}/>}>
                                                <CheckRounded classes={{root: classes.validateAction}}/>
                                            </Tooltip>
                                        </Fab>
                                        <Fab
                                            disabled={actionButtonsDisabled}
                                            color="secondary"
                                            size={'small'}
                                            className={classes.cancelButton}
                                            onClick={() => openDialogType('refuse')}
                                        >
                                            <Tooltip title={<FormattedMessage id={"picking.quote.detail.header.actions.refuse"}/>}>
                                                <CloseRounded classes={{root: classes.cancelAction}}/>
                                            </Tooltip>
                                        </Fab>
                                    </>
                                )
                            }
                            {
                                quote.state === WAITING_CUSTOMER && (
                                    <>
                                        <Fab
                                            disabled={isProcessing || isSubmitting}
                                            color="default"
                                            size={'small'}
                                            className={classes.editButton}
                                            onClick={() => openDialogType('edit')}
                                        >
                                            <Tooltip title={<FormattedMessage id={"picking.quote.detail.header.actions.retailer_edit"}/>}>
                                                <LockOpen classes={{root: classes.editAction}}/>
                                            </Tooltip>
                                        </Fab>
                                        <Fab
                                            color="primary"
                                            size={'small'}
                                            className={classes.validationButton}
                                            onClick= {() => {openDialogType('forceCustomerValidation')}}
                                        >
                                            <Tooltip title={<FormattedMessage id={'picking.quote.detail.header.actions.accept'}/>}>
                                                <CheckRounded classes={{root: classes.validateAction}}/>
                                            </Tooltip>
                                        </Fab>
                                    </>
                                )
                            }
                        </span>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>

            {renderConfirmationDialogs()}

            <div>
                <span className={classes.orderActions}>
                    <span>
                        {intl.formatMessage({id: "picking.quote.detail.header.downloadQuote"})} :
                    </span>
                    <Tooltip
                        title={canAccept ? intl.formatMessage({id: "picking.quote.detail.header.pdf_not_synchronized"}) : ''}
                    >
                        <IconButton disabled={downloadingPdf} onClick={downloadPdf} size="large">
                            <ArrowDownward className={classes.downloadPdf}/>
                        </IconButton>
                    </Tooltip>
                </span>
            </div>
        </div>
    );
};

export default withStyles(detailStyle)(injectIntl(QuoteDetailHeaderActions));
