import {fetch} from "utils/dataAccess";

export default async function print(shipmentDocument,currentOrganization)  {
    // add preferred printers
    const values = {
        'preferredPrinters': JSON.parse(localStorage.getItem(`${currentOrganization.id}_preferredPrinters`)),
    }

    const response = await fetch(`/document/${shipmentDocument.id}/print`, {
        method: "POST",
        body: JSON.stringify(values),
    });

    return await response.json();
}
