import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";

// @mui/material components
import {
    withStyles
} from "@mui/styles";

// @mui/icons-material
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// components
import Button from "components/button/button";
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import ButtonsWrapper from "../button/buttonsWrapper";
import VariationChip from "domain/components/products/VariationChip";
import AddVariationModal from "../modal/addVariationModal";

// styles
import CreateProductFormStyle from "assets/jss/views/stockeasy/product/create/form/createProductFormStyle";

// utils
import {validate, createVariationsValidations, getVariationsKeys} from "../form/formValidation";
import {FormattedMessage, injectIntl} from "react-intl";
import { v4 as uuidv4 } from 'uuid';

import localStorageSE from "../localStorageManager/localStorageSE";

class OptionsStep extends Component {
    constructor(props) {
        super(props);
        const product = localStorageSE.getProductData() ?? false;

        this.state = {
            productData: product,
            isStepValid: true,
            existingColors: getVariationsKeys(product.variations),
            validations: null,
            errorMessage: null,
            isPopinOpen: false,
            modalVariation: null,
            modalType: null,
        };
    }

   componentDidMount() {
       const product = localStorageSE.getProductData();
       let scannedBarcode = null;

       let searchParams = new URLSearchParams(window.location.search);
       let scannedForVariation = searchParams.get('variation');
       searchParams.delete('variation');

       if (scannedForVariation === 'true') {
           scannedBarcode = searchParams.get('scannedBarcode');
           searchParams.delete('scannedBarcode');
       }
       this.props.history.replace(searchParams);

       if (
           product.variations[0].options === null || Object.keys(product.variations[0].options).length === 0
       ) {
           this.handleVariationSelection(product.variations[0]);
       }

       if (scannedBarcode) {
           this.createVariation(scannedBarcode);
       }
   }

    // MANAGE Modal
    toggleModal(open) {
        this.setState({
            isPopinOpen: open
        });
    }

    renderModal() {
        const {isPopinOpen, modalType, errorMessage, productData, barcode} = this.state;

        const modalDefaultProps = {
            open: isPopinOpen,
            onClose: () => this.toggleModal(false),
            type: modalType,
            error: errorMessage,
            productData: productData,
            barcode: barcode
        };

        if (modalType === 'creation') {
            return (
                <AddVariationModal
                    {...modalDefaultProps}
                    submit={(barcode, options, sku, weight) => this.addVariation(barcode, options, sku, weight)}
                    options={this.props.options}
                />
            );
        } else {
            return (
                <AddVariationModal
                    {...modalDefaultProps}
                    selectedVariation={this.state.modalVariation}
                    submit={(barcode, options, sku, weight) => this.updateVariation(barcode, options, sku, weight)}
                    delete={() => this.deleteVariation(this.state.modalVariation)}
                    options={this.props.options}
                />
            );
        }
    }

    // VARIATIONS HANDLING
    composeVariationName(options, weight, addProductName = true) {
        const {productData} = this.state;
        let productName = addProductName ? productData.name : '';
        let suffix = '';

        Object.keys(options).forEach(option => {
           suffix += ' - ' + options[option];
        });

        return productName + suffix;
    }

    createVariationObject(barcode, options, sku, pictures, weight) {
        const {productData} = this.state;
        if (!pictures) {
            pictures = [];
        }

        return {
            id: uuidv4(),
            barcode: barcode,
            options: options,
            sku: sku ?? barcode,
            name: this.composeVariationName(options, weight),
            selected: barcode !== '' || sku !== '',
            default: false,
            pictures: pictures,
            price: productData.price,
            outletPrice: productData.outletPrice,
            stock: null,
            weight: weight,
        };
    }

    createVariation(barcode = null) {
        this.setState(() => {
            return {
                barcode: barcode,
                modalVariation: null,
                modalType: 'creation',
                errorMessage: null,
            };
        }, () => this.toggleModal(true));
    }

    handleVariationSelection(selectedVariation) {
        this.setState(() => {
            return {
                modalType: 'update',
                modalVariation: selectedVariation,
                errorMessage: null,
            };
        }, () => this.toggleModal(true));
    }

    persistVariation(selectedVariation, callback = null) {
        const {productData} = this.state;
        let computedVariations = [];

        productData.variations.forEach((variation, index) => {
            if (selectedVariation.id === variation.id) {

                variation.selected = selectedVariation.sku !== '';
                variation.weight = selectedVariation.weight;
                variation.options = selectedVariation.options;
                variation.name = selectedVariation.name;
            }
            computedVariations[index] = variation;
        })

        this.setState(prevState => {
            return {
                productData: {
                    ...prevState.productData, variations: computedVariations
                }
            };
        }, () => {
            if (callback) {
                callback();
            }
            localStorageSE.updateProductData({
                ...localStorageSE.getProductData(),
                variations: computedVariations
            })
        });
    }

    updateVariation(barcode, options, sku, weight) {
        const {modalVariation} = this.state;

        modalVariation.sku = sku ?? barcode;
        modalVariation.barcode = barcode;
        modalVariation.options = options;
        modalVariation.name = this.composeVariationName(options, weight);
        modalVariation.weight = weight;

        this.persistVariation(modalVariation, () => this.toggleModal(false));
    }

    addVariation(barcode, options, sku, weight) {
        const {productData} = this.state;

        const computedVariations = [...productData.variations];
        const newVariation = this.createVariationObject(barcode, options, sku, productData.pictures, weight);
        computedVariations.push(newVariation)

        this.setState(prevState => {
            return {
                modalVariation: newVariation,
                productData: {
                    ...prevState.productData,
                    variations: computedVariations
                }
            }
        }, () => {
            this.toggleModal(false);
            localStorageSE.updateProductData(
                {
                    ...localStorageSE.getProductData(),
                    variations: computedVariations
                }
            )
        });
    }

    deleteVariation() {
        const {productData, modalVariation} = this.state;

        const computedVariations = [...productData.variations];

        computedVariations.forEach((variation, index) => {
            if (variation.id === modalVariation.id) {
                computedVariations.splice(index, 1);
            }
        })

        this.setState(prevState => {
            return {
                modalVariation: null,
                productData: {
                    ...prevState.productData, variations: computedVariations
                }
            }
        }, () => {
            this.toggleModal(false);
            localStorageSE.getProductData(this.state.productData);
        });
    }

    displayTooltip(sku) {
        const {intl} = this.props;
        return sku ? intl.formatMessage({id: "stockeasy.product.ean"}) + sku : intl.formatMessage({id: "stockeasy.product.no_ean"});
    }

    displayVariationsChip(variations) {
        const variationsList = [];

        variations.map(variation => {
            variationsList.push(
                <VariationChip
                    tooltip={this.displayTooltip(variation.sku)}
                    selected={variation.selected}
                    key={variation.id}
                    label={this.composeVariationName(variation.options, variation.weight)}
                    onClick={() => this.handleVariationSelection(variation)}
                />
            )
        });

        return variationsList;
    }

    // VALIDATION
    writeErrorMessage(validations) {
        const {intl} = this.props;

        let result = intl.formatMessage({id:"stockeasy.color.validation.form.error"});
        validations.forEach(validation => {
            if (!validation.valid) {
                if ('options' === validation.id) {
                    result = intl.formatMessage({id: "stockeasy.option.validation.options.uniqueness.error"});
                } else if ('sku' === validation.id) {
                    result = intl.formatMessage({id: "stockeasy.option.validation.sku.uniqueness.error"});
                } else if ('options_max°' === validation.id) {
                    result = intl.formatMessage({id: "stockeasy.option.validation.options.max.error"});
                } else if ('options_min' === validation.id) {
                    result = intl.formatMessage({id: "stockeasy.option.validation.options.min.error"});
                } else if ('options_same' === validation.id) {
                    result = intl.formatMessage({id: "stockeasy.option.validation.options.same.error"});
                }
            }
        })
        return result;
    }

    handleConfirmStep() {
        const {productData} = this.state;

        // create validations
        const validations = createVariationsValidations(productData.variations);

        // execute validation and update validations
        let isStepValid = true;
        validations.forEach(validation => {
            validation.valid = validate(validation.value, validation);
            if (!validate(validation.value, validation)) {
                isStepValid = false;
            }
        });

        // check validations
        if (isStepValid) {
            localStorageSE.updateProductData(productData);
            this.props.goToNextStep(); // redirect to product step
        } else {
            this.setState({
                errorMessage: this.writeErrorMessage(validations)
            });
        }
    }

    render() {
        const {productData, errorMessage} = this.state;
        const {classes, intl} = this.props;

        const productNotFoundAndNoVariation = productData.variations[0].options === null || Object.keys(productData.variations[0].options).length === 0;

        return (
            <Card>
                <CardBody>
                    <GridContainer className={classes.chipsContainer}>
                        {this.displayVariationsChip(productData.variations)}
                    </GridContainer>

                    <GridContainer className={classes.actionButton}>
                        <GridItem xs={12}>
                            <Button
                                color={"primary"}
                                simple
                                startIcon={<AddCircleOutlineOutlinedIcon/>}
                                onClick={productNotFoundAndNoVariation ?
                                    () => this.handleVariationSelection(productData.variations[0])
                                    : () => this.createVariation()
                                }
                            >
                                <FormattedMessage id={"stockeasy.color.color.button.add"}/>
                            </Button>
                        </GridItem>
                    </GridContainer>

                    <GridItem>
                        <p className={classes.errorMessage}>{errorMessage}</p>
                    </GridItem>

                    <GridContainer>
                        <ButtonsWrapper
                            className={classes.buttonContainer}
                            removeDraft={this.props.removeDraft}
                            goToPreviousStep={this.props.goToPreviousStep}
                            firstButtonContent={intl.formatMessage({id: "stockeasy.button.continue"})}
                            firstButtonDisabled={productNotFoundAndNoVariation}
                            submit={() => this.handleConfirmStep()}
                            step={2}
                        />
                    </GridContainer>
                </CardBody>

                {this.renderModal()}
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        product: state.stockEasy.request.retrieved,
    };
};

export default connect(
    mapStateToProps,
)(withStyles(CreateProductFormStyle)(injectIntl(OptionsStep)));
